import React, {useState, useEffect} from "react";
import axios from '../../helpers/axios-api';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/DataTable/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const columns = [
  { field: 'id', headerName: 'ID', width: 60},
  { field: 'purchase_date', headerName: 'Purchase Date', width: 200},
  { field: 'ebay_item_number', headerName: 'eBay Item Number', width: 150},
  {
    field: 'seller_account_name',
    headerName: 'Seller Account Name',
    width: 200
  },
  {
    field: 'part_number_ordered',
    headerName: 'Part Number Ordered',
    description: 'This is the part number ordered',
    width: 170
  },
  { field: 'qty', headerName: 'QTY', width: 60 },
  { field: 'total_per_unit', headerName: 'Total Per Unit', width: 150},
  { field: 'shipping', headerName: 'Shipping'},
  {
    field: 'total_paid',
    headerName: 'Total Paid',
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'tracking_number',
    headerName: 'Tracking Number/Shipping Status',
    width: 170
    
  },
  { field: 'feedback_left', headerName: 'Feedback Left' },
  { field: 'ebay_account_used_to_buy', headerName: 'Used Account', width: 200},
  { field: 'item_link', headerName: 'itemLink'},
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


function Ebay(props) {
  const { classes } = props;
  const [ebayOrder, setEbayOrder] = useState([]);
  useEffect(() => {
    axios.get(`/get-ebay-order`). then (response => {
        console.log(response.data);
        let ebayOrderData = response.data.ebayOrder;
        ebayOrderData.forEach((value) => {
          value.purchase_date = value.purchase_date.substring(0, value.purchase_date.indexOf('T'));
        });
        setEbayOrder(response.data.ebayOrder);
      });
  }, [])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>eBay Order</h4>
            <p className={classes.cardCategoryWhite}>
              eBay order details
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={columns}
              tableData={ebayOrder}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(Ebay);
