import React from 'react'
import Button from "@mui/material/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    label: {
        textTransform: 'none',
    },
};

function ControlButton(props) {

    const { text, color, variant, onClick, classes, ...other } = props

    return (
        <Button
            variant={variant || "contained"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
        >
            {text}
        </Button>
    )
}

export default withStyles(styles)(ControlButton);