import {getItemFilterLabel} from "./ebay-filter";

export const getFilterAspects = formAspects => {
    const aspects = {};
    Object.keys(formAspects).forEach(aspectName => {
        const aspectValues = [];
        const aspectFormValues = formAspects[aspectName];
        Object.keys(aspectFormValues).forEach(aspectValue => {
            const checked = aspectFormValues[aspectValue];
            if (checked) {
                aspectValues.push(aspectValue);
            }
        });
        if (aspectValues.length > 0) {
            aspects[aspectName] = aspectValues;
        }
    });

    return aspects;
};

export const getItemFilter = formItemFilters => {
    const itemFilters = {};
    Object.keys(formItemFilters).forEach(itemFilterName => {
        const itemFilterValues = [];
        const itemFilterFormValues = formItemFilters[itemFilterName];
        Object.keys(itemFilterFormValues).forEach(itemFilterValue => {
            const checked = itemFilterFormValues[itemFilterValue];
            if (checked) {
                itemFilterValues.push(itemFilterValue);
            }
        });
        if (itemFilterValues.length > 0) {
            itemFilters[itemFilterName] = itemFilterValues;
        }
    });

    return itemFilters;
};

export const getCategoryPath = (categoryId, topCategory, categories) => {
    const path = {...topCategory};
    if (categoryId !== null) {
        let categoryBranch = null;
        categories.forEach(categoryPath => {
            const lastCategory = categoryPath[categoryPath.length - 1];
            if (parseInt(lastCategory.id, 10) === categoryId) {
                categoryBranch = categoryPath;
            }
        });

        if (categoryBranch) {
            categoryBranch.forEach((categoryInPath, index) => {
                path[index+1] = categoryInPath.name;
            });
        }
    }

    return path;
}

export const createArray = (obj) => {
    if (obj) {
        return Object.keys(obj).map(function (key, value) {
            if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
                return "<span style='color: grey'>" + key + ":</span> " + obj[key];
            } else if (Array.isArray(obj[key]) === true) {
                return "<span style='color: grey'>" + key + ":</span> " + obj[key].join(', ');
            } else {
                return "<span style='color: grey'>" + key + ":</span> " + toString(obj[key]);
            }
        });
    } else {
        return null
    }
}

export const createItemFilterArray = (obj) => {
    if (obj) {
        return Object.keys(obj).map(function (key, value) {
            if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
                return "<span style='color: grey'>" + key + ":</span> " + obj[key];
            } else if (Array.isArray(obj[key]) === true) {
                const values = obj[key].map(value => getItemFilterLabel(key, value)).join(', ');
                return "<span style='color: grey'>" + key + ":</span> " + values;
            } else {
                return "<span style='color: grey'>" + key + ":</span> " + toString(obj[key]);
            }
        });
    } else {
        return null
    }
}

export const toString = obj => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join(', ');