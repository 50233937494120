import React, {useEffect, useState} from "react";
import axios from '../../../helpers/axios-api';
import {useDispatch, useSelector} from "react-redux";
import {
    selectIsUpdateSearchResults,
    setIsUpdateSearchResults
} from "../../../features/counter/isUpdateSearchResultsSlice";
import {withRouter} from "react-router-dom";
import {isEbaySearchResultsPage, usePrevious} from "../../../helpers/common";
import {setAlertEbaySearchResults} from "../../../features/ebaySearchResults/alertEbaySearchResultsSlice";
import {setRefreshEbaySearchResults} from "../../../features/ebaySearchResults/refreshEbaySearchResultsSlice";

const style = {
    display: 'flex',
    minWidth: '20px',
    height: '20px',
    borderRadius: '50px',
    backgroundColor: 'green',
    color: 'white',
    fontSize: '12px',
    paddingLeft: '4px',
    paddingRight: '4px',
    lineHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '-12px',
    right: '-12px',
}

const listItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative"
}

const EbayResultsNewLabel = ({location}) => {

    let timer;
    const {pathname} = location;
    const isUpdateSearchResults = useSelector(selectIsUpdateSearchResults);
    const dispatch = useDispatch();
    const [isInitial, setIsInitial] = useState(true);
    const [counter, setCounter] = useState(0);
    const prevCounter = usePrevious(counter) || 0;

    const updateCount = () => {
        timer = !timer && setInterval(() => {
            axios.get(`/get-new-ebay-search-results-count`)
                .then((response) => {
                    setCounter(response.data.total);
                })
                .catch((err) => {
                    console.log(err);
                    console.log('Get new ebay results error');
                });
            },
            60000)
    }

    const getEbaySearchResults = () => {
        axios.get(`/get-new-ebay-search-results-count`)
            .then((response) => {
                setCounter(response.data.total);
                setIsInitial(false);
                dispatch(setIsUpdateSearchResults(false));
            })
            .catch((err) => {
                console.log(err);
                console.log('Get new ebay results error');
            })
    }

    useEffect(() => {
        getEbaySearchResults();
        updateCount();
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (isUpdateSearchResults) {
            getEbaySearchResults();
        }
    }, [isUpdateSearchResults]);

    useEffect(() => {
        if (!isInitial && counter > prevCounter) {
            if (isEbaySearchResultsPage(pathname)) {
                dispatch(setRefreshEbaySearchResults(true));
            } else {
                dispatch(setAlertEbaySearchResults(true));
            }
        }
    }, [counter]);

    let label = null;
    if (counter > 0) {
        label = <span style={style}>{counter}</span>;
    }

    return <div style={listItemStyle}>
        <span>EbayResults</span>
        {label}
    </div>;
}

export default withRouter(EbayResultsNewLabel);