import React, { useState } from 'react'
import { withStyles } from "@material-ui/core";

const styles = {
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
        }
    }
};

function Form(props) {

    const classes = { props };

    const { children, ...other } = props;
    return (
        <form
            className={classes.root}
            autoComplete="off" {...other}
        >
            {props.children}
        </form>
    )
}

export default withStyles(styles)(Form);

