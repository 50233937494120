import deepEqual from "deep-equal";
import {matchPath} from "react-router-dom";
import {useEffect, useRef} from "react";

export const isEbaySearchResultsPage = pathname => !!matchPath(pathname, {path: '/admin/ebay-results'})

export const getOffset = (page, pageSize) => {
    return (page * pageSize).toString();
};

export const shallowEqualObject = (objA, objB) => {
    if (objA === objB) {
        return true;
    }

    if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
        return false;
    }

    let keysA = Object.keys(objA);
    let keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    var bHasOwnProperty = hasOwnProperty.bind(objB);
    for (let i = 0; i < keysA.length; i++) {
        if (!bHasOwnProperty(keysA[i]) || !deepEqual(objA[keysA[i]], objB[keysA[i]])) {
            return false;
        }
    }

    return true;
}

export const htmlDecode = input => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const makeId = length => {
    let result = '';
    let characters = '0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i += 1 ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }

    return result;
}

export const makeOrderId = () => {
    return `${makeId(2)}-${makeId(5)}-${makeId(5)}`;
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}