import { createSlice } from '@reduxjs/toolkit'

export const refreshEbaySearchResultsSlice = createSlice({
    name: 'refreshEbaySearchResults',
    initialState: {
        value: false,
    },
    reducers: {
        setRefreshEbaySearchResults: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setRefreshEbaySearchResults } = refreshEbaySearchResultsSlice.actions;

export const selectRefreshEbaySearchResults = state => state.refreshEbaySearchResults.value;

export default refreshEbaySearchResultsSlice.reducer;