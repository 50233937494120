import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/siasapp-dashboard/components/footerStyle.jsx";
import SoundEventHowler from "../Common/SoundEventHowler";
import bell from "../../sounds/bell.mp3";

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <SoundEventHowler
            src={bell}
        />
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()} Siasapp.com
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
