import React from "react";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Register from "@material-ui/icons/GroupAdd";
import Login from "@material-ui/icons/LockOpen";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import Ebay from "views/Ebay/Ebay.jsx";
import EbaySearch from "views/Ebay/EbaySearch";

// core components/views for Auth layout
import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import EbayResults from "./views/Ebay/EbayResults";
import EbayOrders from "./views/Ebay/EbayOrders";
import Settings from "./views/Settings/Settings";
import EbayResultsNewLabel from "./components/Sidebar/components/EbayResultsNewLabel";
import WarehouseList from "./views/Warehouse/WarehouseList";
import WarehouseGroup from "./views/Warehouse/WarehouseGroup";
import AmazonOrders from "./views/Amazon/AmazonOrders";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAmazon} from "@fortawesome/free-brands-svg-icons";
import {faEbay} from "@fortawesome/free-brands-svg-icons";
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';

const AmazonIcon = props => <FontAwesomeIcon {...props} icon={faAmazon} />;
const EbayIcon = props => <FontAwesomeIcon {...props} icon={faEbay} />;

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   label: "",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   isEnabled: true,
  //   layout: "/admin"
  // },
  {
    path: "/ebay-search",
    name: "EbaySearch",
    label: "",
    icon: SavedSearchIcon,
    component: EbaySearch,
    isEnabled: true,
    layout: "/admin"
  },
  {
    path: "/ebay-results",
    name: "EbayResults",
    label: <EbayResultsNewLabel/>,
    icon: PlaylistAddCheckIcon,
    component: EbayResults,
    isEnabled: true,
    layout: "/admin"
  },
  {
    path: "/ebay-orders",
    name: "EbayOrders",
    label: "",
    icon: EbayIcon,
    component: EbayOrders,
    isEnabled: true,
    layout: "/admin"
  },
  {
    path: "/warehouse-stock-list",
    name: "Warehouse Stock",
    label: "",
    icon: "content_paste",
    component: WarehouseList,
    isEnabled: false,
    layout: "/admin"
  },
  {
    path: "/warehouse-stock-group",
    name: "Warehouse Stock",
    label: "",
    icon: "content_paste",
    component: WarehouseGroup,
    isEnabled: true,
    layout: "/admin"
  },
  {
    path: "/amazon-orders",
    name: "Amazon Orders",
    label: "",
    icon: AmazonIcon,
    component: AmazonOrders,
    isEnabled: true,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    label: "",
    icon: BuildCircleIcon,
    component: Settings,
    isEnabled: true,
    layout: "/admin"
  },
  {
    path: "/login-page",
    name: "Login Page",
    label: "",
    icon: Login,
    component: LoginPage,
    isEnabled: true,
    layout: "/auth"
  },
  {
    path: "/register-page",
    name: "Register Page",
    label: "",
    icon: Register,
    component: RegisterPage,
    isEnabled: true,
    layout: "/auth"
  }
];

export default dashboardRoutes;
