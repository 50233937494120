import Button from "@mui/material/Button";
import React, {useEffect, useState, useRef} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import axios from '../../helpers/axios-api';
import {
    CircularProgress,
    FormControlLabel,
    IconButton,
    Switch,
    Tab,
    Tabs,
} from "@mui/material";
import {decode} from "html-entities";
import {
    condition,
    ITEM_FILTER_CONDITION_NAME,
    ITEM_FILTER_LISTING_TYPE_NAME,
    ITEM_FILTER_LOCATED_IN_NAME,
    listingType,
    locatedIn
} from "../../helpers/ebay-filter";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from '@mui/icons-material/Close';
import {getCategoryPath, getFilterAspects, getItemFilter} from "../../helpers/ebay-search";
const a11yProps = index => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const boxStyle = {
    marginBottom: "15px"
}

export default function AddSearch(props) {
    const defaultValues = {
        name: '',
        keyword: '',
        category_id: undefined,
        category_name: undefined,
        category_top_id: undefined,
        category_top_name: undefined,
        categories: {},
        aspects: {},
        itemFilter: {},
    };

    const { handleFinishAdd } = props;
    const [category, setCategory] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [categoryTree, setCategoryTree] = useState([]);
    const [aspects, setAspects] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingCategoryTree, setLoadingCategoryTree] = useState(false);
    const [loadingAspects, setLoadingAspects] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [formValues, setFormValues] = useState(defaultValues);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [status, setStatus] = useState(true);

    const minPriceInputRef = useRef(null);
    const maxPriceInputRef = useRef(null);

    useEffect(() => {
        if (minPriceInputRef.current) {
            minPriceInputRef.current.focus();
        }
    }, [minPrice]);

    useEffect(() => {
        if (maxPriceInputRef.current) {
            maxPriceInputRef.current.focus();
        }
    }, [maxPrice]);

    const openSearchModal = () => {
        getCategories();
        setSearchModalOpen(true);
    }

    const closeSearchModal = () => {
        setSearchModalOpen(false);
    }

    const handleMinPriceChange = e => {
        const minPriceValue = e.target.value ? parseInt(e.target.value, 10) : 0;
        setMinPrice(minPriceValue);
        minPriceInputRef.current.focus();
    }

    const handleStatusChange = event => {
        setStatus(event.target.checked);
    };

    const handleMaxPriceChange = e => {
        const maxPriceValue = e.target.value ? parseInt(e.target.value, 10) : 0;
        setMaxPrice(maxPriceValue);
    }

    const handleCategoryChange = (e) => {
        const [id, name] = e.target.value.split('|');
        setCategory(parseInt(id, 10));
        setCategoryName(name);
    }

    const sendFormValues = async () => {

        const categoryId = formValues.category_id ? parseInt(formValues.category_id, 10) : null;
        const itemFilter = getItemFilter(formValues.itemFilter);
        itemFilter.MinPrice = minPrice;
        itemFilter.MaxPrice = maxPrice;

        const request = {
            name: formValues.name ? encodeURIComponent(formValues.name) : null,
            keyword: formValues.keyword ? encodeURIComponent(formValues.keyword) : null,
            category_id: categoryId,
            category_name: formValues.category_name ? formValues.category_name : null,
            category_top_id: category ? parseInt(category, 10) : null,
            category_top_name: categoryName ? categoryName : null,
            categories: getCategoryPath(categoryId, {"0": categoryName}, categoryTree),
            filters: {
                aspects: getFilterAspects(formValues.aspects),
                itemFilter: itemFilter
            },
            status: status ? 1 : 0
        }

        axios.post(`/create-ebay-search`, request).then(() => {
            setSearchModalOpen(false);
            handleFinishAdd();
        });
    }

    const handleCategoryTreeChange = (e) => {
        const [id, name] = e.target.value ? e.target.value.split('|') : [null, null];
        setFormValues({
            ...formValues,
            category_id: +id,
            category_name: name
        });
        setLoadingAspects(true);
        axios.get(`/ebay-category-aspects/${id}`)
            .then(response => {
                setAspects(response.data.data);
                setLoadingAspects(false);
            })
    }

    const handleNameChange = (e) => {
        setFormValues({
            ...formValues,
            name: e.target.value,
        });
    }

    const handleKeywordChange = (e) => {
        setFormValues({
            ...formValues,
            keyword: e.target.value,
        });
    }

    const getCategories = () => {
        setLoadingCategories(true);
        axios.get(`/ebay-categories-by-level/1`)
            .then(response => {
                const mainCategories = response.data.data.map(item => {
                    item.label = decode(item.name);
                    item.value = `${item.id}|${item.label}`;
                    return item;
                });
                setCategories(mainCategories);
                setLoadingCategories(false);
            })
        setLoadingCategories(false);
    }

    const getCategorySubtree = () => {
        if (category) {
            setLoadingCategoryTree(true);
            axios.get(`/ebay-category-subtree/${category}`)
                .then(response => {
                    setCategoryTree(response.data.data);
                    setLoadingCategoryTree(false);
                });
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                style={{width: "80%", overflow: "auto"}}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        getCategorySubtree();
    }, [category])

    const handleCheckboxHandler = (aspectName, value, event) => {
        const aspects = {...formValues.aspects};
        const aspectValues = aspects[aspectName] ? aspects[aspectName] : {};
        aspectValues[value] = event.target.checked;
        aspects[aspectName] = aspectValues;
        const newFormValues = {...formValues, aspects: aspects};
        setFormValues(newFormValues);
    }

    const handleItemFilterCheckboxHandler = (itemFilterName, value, event) => {
        const itemFilter = {...formValues.itemFilter};
        const itemFilterValues = itemFilter[itemFilterName] ? itemFilter[itemFilterName] : {};
        itemFilterValues[value] = event.target.checked;
        itemFilter[itemFilterName] = itemFilterValues;
        const newFormValues = {...formValues, itemFilter: itemFilter};
        setFormValues(newFormValues);
    }

    const leafCategoryValue = (formValues.category_id && formValues.category_name)
        ? `${formValues.category_id}|${formValues.category_name}`
        : '';

    const mainCategoryValue = (category && categoryName)
        ? `${category}|${categoryName}`
        : '';

    const categoryTreeOptions = categoryTree.map((path, index) => {
        const leaf = path[path.length-1];
        const label = path.map(category => category.name).join(' > ');
        return (
            <MenuItem
                key={index}
                value={`${leaf.id}|${leaf.name}`}
            >
                {label}
            </MenuItem>
        );
    })

    const itemFilterIndexStart = aspects.filter(aspect => aspect.values.length > 0).length;

    return (
        <Stack spacing={1} direction="row">
            <Button variant="contained" onClick={openSearchModal}>Add New</Button>
            <Dialog open={searchModalOpen} onClose={closeSearchModal} fullWidth maxWidth="xl">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Add New EbaySearch
                    <IconButton onClick={closeSearchModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box style={boxStyle}>
                        <InputLabel>Name</InputLabel>
                        <TextField
                            autoFocus
                            required={true}
                            margin={"dense"}
                            id={"name"}
                            label={"Name"}
                            fullWidth
                            value={formValues.name}
                            placeholder={'Please, enter name'}
                            onChange={handleNameChange}
                        />
                    </Box>
                    <Box style={boxStyle}>
                        <InputLabel>Keyword</InputLabel>
                        <TextField
                            autoFocus
                            required={false}
                            margin={"dense"}
                            id={"keyword"}
                            label={"Keyword"}
                            fullWidth
                            value={formValues.keyword}
                            placeholder={'Please, enter keyword'}
                            onChange={handleKeywordChange}
                        />
                    </Box>
                    {loadingCategories && <Box>
                        <CircularProgress color="secondary" />
                    </Box>}
                    {!loadingCategories && <Box style={boxStyle}>
                        <InputLabel id={"main-category"}>Main Category</InputLabel>
                        <Select fullWidth
                            value={mainCategoryValue}
                            onChange={handleCategoryChange}
                        >
                            {categories.map(option => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>}
                    {loadingCategoryTree && <Box>
                        <CircularProgress color="secondary" />
                    </Box>}
                    {!loadingCategoryTree && categoryTree.length > 0 && <Box style={boxStyle}>
                        <InputLabel>Child Category</InputLabel>
                        <Select fullWidth
                                value={leafCategoryValue}
                                label="Child Category"
                                onChange={handleCategoryTreeChange}
                        >
                            {categoryTreeOptions}
                        </Select>
                    </Box>}
                    {loadingAspects && <Box>
                        <CircularProgress color="secondary" />
                    </Box>}
                    {!loadingAspects && aspects.length > 0 && <Box
                        sx={{
                            flexGrow: 1,
                            bgcolor: 'background.paper',
                            display: 'flex',
                            height: 450,
                            marginBottom: "15px"
                        }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            visibleScrollbar
                        >
                            {aspects.filter(aspect => aspect.values.length > 0).map((element, index) => {
                                return (
                                    <Tab key={'tab'+index} label={element.name} {...a11yProps({index})} />
                                );
                            })}
                            <Tab key={'tab'+itemFilterIndexStart} label={'Condition'} {...a11yProps(itemFilterIndexStart)} />
                            <Tab key={'tab'+(itemFilterIndexStart+1)} label={'Buying Format'} {...a11yProps(itemFilterIndexStart+1)} />
                            <Tab key={'tab'+(itemFilterIndexStart+2)} label={'Location'} {...a11yProps(itemFilterIndexStart+2)} />
                            <Tab key={'tab'+(itemFilterIndexStart+3)} label={'Price'} {...a11yProps(itemFilterIndexStart+3)} />
                        </Tabs>
                        {aspects.filter(aspect => aspect.values.length > 0).map((element, index) => {
                            return(
                                <TabPanel value={tabValue} key={'tabPanel'+index} index={index}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {element.values.map((aspectValue, index) => {
                                                const checked = formValues.aspects[element.name]
                                                    ? !!formValues.aspects[element.name][aspectValue]
                                                    : false;
                                                return <FormControlLabel
                                                    key={'controlLabel' + index}
                                                    control={<Checkbox
                                                        checked={checked}
                                                        onChange={event => handleCheckboxHandler(element.name, aspectValue, event)}
                                                    />}
                                                    label={aspectValue}
                                                    labelPlacement="end"
                                                    sx={{flexBasis: "32%"}}
                                                />
                                            }
                                        )}
                                    </Box>
                                </TabPanel>
                            );
                        })}
                        <TabPanel value={tabValue} key={'tabPanel'+itemFilterIndexStart} index={itemFilterIndexStart}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {Object.keys(condition).map((conditionValue, index) => {
                                    const name = condition[conditionValue];
                                    const checked = formValues.itemFilter[ITEM_FILTER_CONDITION_NAME]
                                        ? !!formValues.itemFilter[ITEM_FILTER_CONDITION_NAME][conditionValue]
                                        : false;
                                    return <FormControlLabel
                                        key={'controlLabel' + index}
                                        control={<Checkbox
                                            checked={checked}
                                            onChange={event => handleItemFilterCheckboxHandler(ITEM_FILTER_CONDITION_NAME, conditionValue, event)}
                                        />}
                                        label={name}
                                        labelPlacement="end"
                                        sx={{flexBasis: "32%"}}
                                    />
                                    }
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value={tabValue} key={'tabPanel'+(itemFilterIndexStart+1)} index={(itemFilterIndexStart+1)}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {Object.keys(listingType).map((listingTypeValue, index) => {
                                        const name = listingType[listingTypeValue];
                                        const checked = formValues.itemFilter[ITEM_FILTER_LISTING_TYPE_NAME]
                                            ? !!formValues.itemFilter[ITEM_FILTER_LISTING_TYPE_NAME][listingTypeValue]
                                            : false;
                                        return <FormControlLabel
                                            key={'controlLabel' + index}
                                            control={<Checkbox
                                                checked={checked}
                                                onChange={event => handleItemFilterCheckboxHandler(ITEM_FILTER_LISTING_TYPE_NAME, listingTypeValue, event)}
                                            />}
                                            label={name}
                                            labelPlacement="end"
                                            sx={{flexBasis: "32%"}}
                                        />
                                    }
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value={tabValue} key={'tabPanel'+(itemFilterIndexStart+2)} index={(itemFilterIndexStart+2)}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {Object.keys(locatedIn).map((locatedInValue, index) => {
                                        const name = locatedIn[locatedInValue];
                                        const checked = formValues.itemFilter[ITEM_FILTER_LOCATED_IN_NAME]
                                            ? !!formValues.itemFilter[ITEM_FILTER_LOCATED_IN_NAME][locatedInValue]
                                            : false;
                                        return <FormControlLabel
                                            key={'controlLabel' + index}
                                            control={<Checkbox
                                                checked={checked}
                                                onChange={event => handleItemFilterCheckboxHandler(ITEM_FILTER_LOCATED_IN_NAME, locatedInValue, event)}
                                            />}
                                            label={name}
                                            labelPlacement="end"
                                            sx={{flexBasis: "32%"}}
                                        />
                                    }
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value={tabValue} key={'tabPanel'+(itemFilterIndexStart+3)} index={(itemFilterIndexStart+3)}>
                            <Box key={"minPriceBox"} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: "15px" }}>
                                <InputLabel>MinPrice</InputLabel>
                                <TextField
                                    inputRef={minPriceInputRef}
                                    required={false}
                                    margin={"dense"}
                                    id={"minPrice"}
                                    key={"minPrice"}
                                    label={"MinPrice"}
                                    fullWidth
                                    value={minPrice}
                                    type={"number"}
                                    placeholder={'Please, enter min price'}
                                    onChange={handleMinPriceChange}
                                />
                            </Box>
                            <Box key={"maxPriceBox"} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <InputLabel>MaxPrice</InputLabel>
                                <TextField
                                    inputRef={maxPriceInputRef}
                                    required={false}
                                    margin={"dense"}
                                    id={"maxPrice"}
                                    key={"maxPrice"}
                                    label={"MaxPrice"}
                                    fullWidth
                                    value={maxPrice}
                                    type={"number"}
                                    placeholder={'Please, enter max price'}
                                    onChange={handleMaxPriceChange}
                                />
                            </Box>
                        </TabPanel>
                    </Box>}
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={status}
                                    onChange={handleStatusChange}
                                />
                            }
                            label="Status"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeSearchModal} variant={'outlined'}>Cancel</Button>
                    <Button onClick={sendFormValues} variant={'contained'}>Save</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}