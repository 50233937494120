import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {GridFooterContainer, GridPagination} from "@mui/x-data-grid";
import {formatter} from "../../../helpers/common";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    totalMain: {
        color: '#9c27b0',
        fontWeight: "bold",
    },
    totalSecondary: {
        color: 'green',
        fontWeight: "bold",
    },
};

const EbayOrdersFooter = props => {
    const { classes } = props;
    return (
        <GridFooterContainer>
            <Box sx={{ padding: "10px", display: "flex" }}>
                <div>
                    <span>Total Price:&nbsp;</span>
                    <span className={classes.totalMain}>{formatter.format(props.totalPrice)},&nbsp;</span>
                </div>
                <div>
                    <span>Total Shipping:&nbsp;</span>
                    <span className={classes.totalSecondary}>{formatter.format(props.totalShipping)}</span>
                </div>
            </Box>
            <GridPagination />
        </GridFooterContainer>
    );
}

EbayOrdersFooter.propTypes = {
    totalPrice: PropTypes.number,
    totalShipping: PropTypes.number,
};

export default withStyles(styles)(EbayOrdersFooter);