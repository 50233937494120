import {CircularProgress, Dialog, DialogContent, IconButton} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import React, {useState, useEffect} from "react";
import axios from '../../helpers/axios-api';
import Controls from "../../components/Common/FormControls/Controls";
import Form from "../Common/Form";
import {makeOrderId} from "../../helpers/common";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import SnackbarMessage from "../Common/SnackbarMessage";
import OrderDialogProducts from "./components/OrderDialogProducts";

const styles = {
    gridItem: {},
    textHelper: {
        fontSize: '10px',
    },
    formInput: {
        marginTop: '8px'
    },
    formButtons: {
        display: 'flex',
        justifyContent: 'center'
    },
    orderCircleDialog: {
        width: '100%',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const sx = {
    '& .MuiTextField-root': { m: 1 },
}

const boxStyle = {
    marginBottom: "15px"
}

function OrderDialog(props) {

    const {
        initialFValues,
        initialProducts,
        formType,
        orderId,
        modalTitle,
        statuses,
        closeModal,
        isLoading,
        handleOrderFinished,
        classes
    } = props;
    const [rows, setRows] = useState([]);
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [message, setMessage] = useState('Something went wrong!');
    const [severity, setSeverity] = useState('error');

    useEffect(() => {
        setValues(initialFValues);
    }, [initialFValues])

    useEffect(() => {
        setRows(initialProducts);
    }, [initialProducts])

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })

        validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }

    const createRow = function (name, partNumber, link, quantity, price) {
        const errors = {
            name: null,
            partNumber: null,
            link: null,
            quantity: '',
            price: null
        };

        return {
            id: 0,
            name,
            partNumber,
            link,
            quantity,
            price,
            errors
        }
    };

    const validateRows = () => {
        const newRows = [];
        const rowsResultsValidation = rows.map(row => {
            let newErrors = {...row.errors};
            Object.keys(row).forEach(name => {
                if (name !== 'errors' && name !== 'id') {
                    const value = row[name];
                    const resultRowErrors = {...validateInputRow(name, value, row)};
                    newErrors[name] = resultRowErrors[name];
                }
            });
            console.log(newErrors);
            const newRow = {...row, errors: newErrors};
            newRows.push(newRow);

            return Object.values(newRow.errors).every(x => x === "");
        });


        setRows(newRows);
        return rowsResultsValidation.every(x => x === true);
    }

    const validateInputRow = (name, value, row) => {
        let temp = { ...row.errors }
        if (name === 'name')
            temp.name = value ? "" : "This field is required.";
        if (name === 'link')
            temp.link = value ? "" : "This field is required.";
        if (name === 'partNumber')
            temp.partNumber = value ? "" : "This field is required.";
        if (name === 'quantity')
            temp.quantity = (value.length != 0 && parseInt(value) > 0) ? "" : "This field is required."
        if (name === 'price')
            temp.price = (value.length != 0 && parseFloat(value) > 0) ? "" : "This field is required."

        return temp;
    }

    const handleRowInputChange = (e, row, changeIndex) => {
        const value = e.target.value;
        const name = e.target.name;
        const errors = validateInputRow(name, value, row);
        const newRows = rows.map((row, index) => {
            if (changeIndex === index) {
                return { ...row, [name]: value, errors };
            }
            return row;
        });

        setRows(newRows);
    }

    const addRow = () => {
        let newRow = createRow('', '', '', 1, 0);
        let newRows = [...rows];
        newRows.push(newRow);
        setRows(newRows);
    }

    const removeRow = (index) => () => {
        let newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('orderNumber' in fieldValues)
            temp.orderNumber = fieldValues.orderNumber ? "" : "This field is required.";
        if ('purchaseDate' in fieldValues)
            temp.purchaseDate = fieldValues.purchaseDate ? "" : "This field is required.";
        if ('buyer' in fieldValues)
            temp.buyer = fieldValues.buyer ? "" : "This field is required.";
        if ('status' in fieldValues)
            temp.status = fieldValues.status.length != 0 ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const createOrder = () => {
        const products = rows.map(row => {
            return {
                id: row.id ? row.id : 0,
                name: row.name ? row.name : null,
                partNumber: row.partNumber ? row.partNumber : null,
                link: row.link ? row.link : null,
                quantity: row.quantity ? +row.quantity : null,
                price: row.price ? +row.price : null,
            }
        });

        const request = {
            ...values,
            purchaseDate: values.purchaseDate ? moment(values.purchaseDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
            products
        }

        axios.post(`/add-ebay-order`, request).then((response) => {
            handleFinish(response.data);
        }).catch(e => {
            console.log(e);
            setMessage('Something went wrong!');
            setSeverity('error');
            setMessageShow(true);
        });
    }

    const updateOrder = () => {
        const products = rows.map(row => {
            return {
                id: row.id ? row.id : 0,
                name: row.name ? row.name : null,
                partNumber: row.partNumber ? row.partNumber : null,
                link: row.link ? row.link : null,
                quantity: row.quantity ? +row.quantity : null,
                price: row.price ? +row.price : null,
            }
        });

        const request = {
            ...values,
            purchaseDate: values.purchaseDate ? moment(values.purchaseDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
            products
        }

        axios.patch(`/update-ebay-order/${orderId}`, request).then((response) => {
            handleFinish(response.data);
        }).catch(e => {
            console.log(e);
            setMessage('Something went wrong!');
            setSeverity('error');
            setMessageShow(true);
        });
    }

    const handleFinish = (responseData) => {
        if (responseData.ok) {
            setMessage(responseData.message);
            setSeverity('success');
            setMessageShow(true);
            setTimeout(() => {
                setRows([]);
                resetForm();
                closeModal();
                handleOrderFinished();
            }, 1000);
        } else {
            setMessage(responseData.message);
            setSeverity('error');
            setMessageShow(true);
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (rows.length === 0) {
            setMessage('Please add at least one product!');
            setSeverity('error');
            setMessageShow(true);

            return;
        }

        if (!validateRows()) {
            setMessage('Please fill out all fields for products!');
            setSeverity('error');
            setMessageShow(true);

            return;
        }

        if (validate() && formType === 'add') {
            createOrder();
            return;
        } else if (validate() && formType === 'edit') {
            updateOrder();
            return;
        }

        setMessage('Please fill out all necessary fields!');
        setSeverity('error');
        setMessageShow(true);
    }

    const resetFullForm = () => {
        setRows([]);
        resetForm();
    }

    const generateOrderNumber = (e) => {
        e.preventDefault();
        const orderNumber = makeOrderId();
        setValues({
            ...values,
            orderNumber
        });
    }

    return(
        <Dialog open={props.modalOpen} onClose={props.closeModal} maxWidth="l">
            <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {modalTitle}
                <IconButton onClick={props.closeModal}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {isLoading && <DialogContent className={classes.orderCircleDialog}>
                <CircularProgress color="secondary" />
            </DialogContent>}
            {!isLoading && <DialogContent className={classes.orderDialog}>
                <SnackbarMessage
                    open={messageShow}
                    autoHideDuration={6000}
                    setOpen={setMessageShow}
                    severity={severity}
                    message={message}
                />
                <Box style={boxStyle}>
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={16}>
                            <Grid item xs={6} className={classes.gridItem}>
                                <Controls.Input
                                    name="orderNumber"
                                    label="Order Number"
                                    value={values.orderNumber}
                                    onChange={handleInputChange}
                                    error={errors.orderNumber}
                                    fullWidth={true}
                                    allowHelperText={false}
                                    className={classes.formInput}
                                />
                                <div>
                                    <a className={classes.textHelper} href={'#'} onClick={generateOrderNumber}>
                                        Generate Order Number
                                    </a>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem} >
                                <Box sx={sx}>
                                    <Controls.FormDatePicker
                                        name="purchaseDate"
                                        label="Purchase Date"
                                        value={values.purchaseDate}
                                        format={"dd.MM.yyyy"}
                                        onChange={handleInputChange}
                                        error={errors.purchaseDate}
                                        allowHelperText={false}
                                        inputParams={{
                                            fullWidth: true,
                                            style: {marginTop: "8px", paddingRight: "16px"}
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem}>
                                <Controls.Input
                                    name="buyer"
                                    label="Buyer"
                                    value={values.buyer}
                                    onChange={handleInputChange}
                                    error={errors.buyer}
                                    fullWidth={true}
                                    allowHelperText={false}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem}>
                                <Controls.FormSelect
                                    name="status"
                                    label="Status"
                                    value={values.status}
                                    displayEmpty={true}
                                    onChange={handleInputChange}
                                    error={errors.status}
                                    fullWidth={true}
                                    allowHelperText={false}
                                    options={statuses}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <Controls.Input
                                    name="delivery"
                                    label="Delivery"
                                    value={values.delivery}
                                    onChange={handleInputChange}
                                    error={errors.delivery}
                                    fullWidth={true}
                                    allowHelperText={false}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <OrderDialogProducts
                                rows={rows}
                                addRow={addRow}
                                removeRow={removeRow}
                                handleRowInputChange={handleRowInputChange}
                            />
                            <Grid item xs={12}>
                                <div className={classes.formButtons}>
                                    <Controls.ControlButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{marginRight: "15px"}}
                                        text="Submit"
                                    />
                                    <Controls.ControlButton
                                        text="Reset"
                                        onClick={resetFullForm}
                                        variant="outlined"
                                        color="primary"
                                        type="reset"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                </Box>
            </DialogContent>}
        </Dialog>
    );
}

export default withStyles(styles)(OrderDialog);