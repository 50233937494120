import React, {useState, useEffect} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {
  EVENT_HIDE_EBAY_SEARCH_RESULT_LABEL,
  offEvent,
  onEvent
} from "../../../helpers/events";

const styles = {
  labelStyle: {
    color: "white",
    backgroundColor: "green",
    borderRadius: "3px",
    marginBottom: "5px",
    textAlign: "center",
    padding: "3px",
    maxWidth: "100px"
  }
};

const EbayResultsItemCell = props => {
  const { key, id, itemId, link, viewDate, handleViewed, classes } = props;
  const isInitialHide = !!viewDate;

  const [isHide, setIsHide] = useState(isInitialHide);
  const hideLabel = React.useCallback((e) => setHideLabel(e), []);

  const setHideLabel = (e) => {
    if (e.detail && e.detail.ebaySearchResultId) {
      const triggeredId = e.detail.ebaySearchResultId;
      if (triggeredId === id) {
        setIsHide(true);
      }
    }
  }

  useEffect(() => {
    onEvent(EVENT_HIDE_EBAY_SEARCH_RESULT_LABEL, hideLabel);

    return () => {
      offEvent(EVENT_HIDE_EBAY_SEARCH_RESULT_LABEL, hideLabel);
    }
  }, []);

  let label = isHide ? '' : <div className={classes.labelStyle}>NEW</div>;
  const date = isHide
      ? <a href={link} target={'_blank'}>{itemId}</a>
      : <a href={link}
           onClick={() => handleViewed(id)}
           onAuxClick={() => handleViewed(id)}
           target={'_blank'}
      >{itemId}</a>;

  return <div key={key}>
    {label}
    {date}
  </div>;
}

export default withStyles(styles)(EbayResultsItemCell);
