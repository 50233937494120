import {IconButton} from "@mui/material";
import React from "react";
import Controls from "../../../components/Common/FormControls/Controls";
import withStyles from "@material-ui/core/styles/withStyles";
import ClearIcon from "@mui/icons-material/Clear";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const styles = {
    gridItem: {},
    textHelper: {
        fontSize: '10px',
    },
    formInput: {
        marginTop: '8px'
    },
}

const boxStyle = {
    marginBottom: "15px"
}

function OrderDialogProduct(props) {

    const { row, key, index, removeRow, handleRowInputChange, classes } = props;
    const { errors } = row;

    return(
        <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>
                <Controls.Input
                    name="name"
                    label="Product Name"
                    value={row.name}
                    onChange={e => handleRowInputChange(e, row, index)}
                    error={errors.name}
                    fullWidth={true}
                    allowHelperText={false}
                />
            </TableCell>
            <TableCell>
                <Controls.Input
                    name="link"
                    label="Product Link"
                    value={row.link}
                    onChange={e => handleRowInputChange(e, row, index)}
                    error={errors.link}
                    fullWidth={true}
                    allowHelperText={false}
                />
            </TableCell>
            <TableCell>
                <Controls.Input
                    name="partNumber"
                    label="Part Number"
                    value={row.partNumber}
                    onChange={e => handleRowInputChange(e, row, index)}
                    error={errors.partNumber}
                    fullWidth={true}
                    allowHelperText={false}
                />
            </TableCell>
            <TableCell>
                <Controls.Input
                    type={'number'}
                    name="quantity"
                    label="Quantity"
                    value={row.quantity}
                    onChange={e => handleRowInputChange(e, row, index)}
                    error={errors.quantity}
                    fullWidth={true}
                    allowHelperText={false}
                />
            </TableCell>
            <TableCell>
                <Controls.Input
                    type={'number'}
                    inputProps={{
                        step: "0.01"
                    }}
                    name="price"
                    label="Price"
                    value={row.price}
                    onChange={e => handleRowInputChange(e, row, index)}
                    error={errors.price}
                    fullWidth={true}
                    allowHelperText={false}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={removeRow(index)} style={{color:'red', fontSize: '17px'}}>
                    <ClearIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default withStyles(styles)(OrderDialogProduct);