import React, {useEffect, useState} from "react";

import axios from '../../helpers/axios-api';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Badge, Grid, IconButton, Select, SwipeableDrawer} from "@mui/material";
import {getOffset} from "../../helpers/common";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import ChatIcon from '@mui/icons-material/Chat';
import NotesPopup from "../../components/Popups/NotesPopup";
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PersonIcon from "@mui/icons-material/Person";
import AmazonOrdersFooter from "./components/AmazonOrdersFooter";
import Controls from "../../components/Common/FormControls/Controls";
import AmazonOrdersExport from "./components/AmazonOrdersExport";
import Box from "@mui/material/Box";
import SnackbarMessage from "../../components/Common/SnackbarMessage";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  exportOrders: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: '9px',
  }
};

const pageSize = 20;

const defaultSort = [{ field: 'date', sort: 'desc'}];
const defaultFilter = {
  orderId: '',
  product: '',
  asin: '',
  status: '',
  purchaseDateFrom: null,
  purchaseDateTo: null
};

const AMAZON_ORDERS_FILTER = 'AMAZON_ORDERS_FILTER';
const AMAZON_ORDERS_SORT = 'AMAZON_ORDERS_SORT';
const AMAZON_ORDERS_SEARCH = 'AMAZON_ORDERS_SEARCH';

function AmazonOrders(props) {
  const { classes } = props;
  const savedFilter = JSON.parse(localStorage.getItem(AMAZON_ORDERS_FILTER));
  const savedSort = JSON.parse(localStorage.getItem(AMAZON_ORDERS_SORT));
  const savedSearch = JSON.parse(localStorage.getItem(AMAZON_ORDERS_SEARCH));
  const [amazonOrders, setAmazonOrders] = useState({
    data: [],
    total: 0,
    amazonTotalPrice: 0,
    amazonTotalCharges: 0,
    amazonTotalProfit: 0
  });
  const [filter, setFilter] = useState(savedFilter || defaultFilter);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(savedSort || defaultSort);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [search, setSearch] = useState(savedSearch || '');
  const [messageShow, setMessageShow] = useState(false);
  const [message, setMessage] = useState('Something went wrong!');
  const [severity, setSeverity] = useState('error');

  const validate = () => {
    const result = {ok: true, errors: {}};
    const { purchaseDateFrom, purchaseDateTo } = filter;
    if (purchaseDateFrom && purchaseDateTo) {
      result.errors.purchaseDate = moment(purchaseDateFrom) > moment(purchaseDateTo)
          ? 'PurchaseDateTo must be equal or greater than PurchaseDateFrom!'
          : '';
    }

    result.ok = Object.values(result.errors).every(x => x === '');

    return result;
  }

  const handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const filterCount = () => {
    let count = 0;
    Object.keys(filter).map(function(objectKey, index) {
      if (filter[objectKey]) count++;
    });
    return count;
  }

  const toggleFilter = expandedFilter =>
      event => {
        if (
            event &&
            (event.type === 'keydown'
                && event.keyCode === 'Tab' ||
                event.keyCode === 'Shift')
        ) {
          return;
        }

        setExpandedFilter(expandedFilter);
      };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }

  const amazonOrdersTableData = amazonOrders.data.map((amazonOrder, index) => {
    return {
      id: amazonOrder.id,
      key: index,
      date: {
        dateCreate: amazonOrder.purchase_date ? moment(amazonOrder.purchase_date).format('DD.MM.YYYY') : null,
        lastDateUpdate: amazonOrder.last_update_date ? moment(amazonOrder.last_update_date).format('DD.MM.YYYY') : null,
      },
      transaction: {
        id: amazonOrder.amazon_order_id ? amazonOrder.amazon_order_id : null,
        source: 'Amazon',
        marketplaceId: amazonOrder.marketplace_id ? amazonOrder.marketplace_id : null,
      },
      buyer: {
        email: amazonOrder.buyer_email ? amazonOrder.buyer_email : null,
        marketplaceId: amazonOrder.marketplace_id ? amazonOrder.marketplace_id : null,
        id: amazonOrder.amazon_order_id ? amazonOrder.amazon_order_id : null,
      },
      product: {
        image: amazonOrder.small_image_url ? amazonOrder.small_image_url : null,
        name: amazonOrder.item_title,
        asin: amazonOrder.asin ? amazonOrder.asin : null,
        ebay_part_number: amazonOrder.ebay_item ? amazonOrder.ebay_item.ebay_part_number : null,
        ebay_product_link: amazonOrder.ebay_item ? amazonOrder.ebay_item.ebay_product_link : null,
        ebay_product_title: amazonOrder.ebay_item ? amazonOrder.ebay_item.ebay_product_title : null
      },
      asin: {
        asin: amazonOrder.asin ? amazonOrder.asin : null,
        sku: amazonOrder.seller_sku ? amazonOrder.seller_sku : null,
        partNumber: amazonOrder.part_number ? amazonOrder.part_number : null,
      },
      price: {
        quantity: amazonOrder.number_of_items ? amazonOrder.number_of_items : 0,
        price: amazonOrder.item_price_amount ? amazonOrder.item_price_amount : 0,
        total: amazonOrder.item_price_total ? amazonOrder.item_price_total : 0,
        promotionDiscount: amazonOrder.promotion_discount_amount ? amazonOrder.promotion_discount_amount : 0,
        promotionDiscountTax: amazonOrder.promotion_discount_tax_amount ? amazonOrder.promotion_discount_tax_amount : 0,
        shippingDiscount: amazonOrder.shipping_discount_amount ? amazonOrder.shipping_discount_amount : 0,
        shippingDiscountTax: amazonOrder.shipping_discount_tax_amount ? amazonOrder.shipping_discount_tax_amount : 0,
        shippingPrice: amazonOrder.shipping_price_amount ? amazonOrder.shipping_price_amount : 0,
        shippingTax: amazonOrder.shipping_tax_amount ? amazonOrder.shipping_tax_amount : 0,
      },
      profit: {
        ebayPrice: amazonOrder.ebay_item_total,
        charges: amazonOrder.item_charges,
        profit: amazonOrder.profit,
      },
      status: {
        name: amazonOrder.order_status ? amazonOrder.order_status : 0,
      },
      notes: amazonOrder.comments ? amazonOrder.comments : [],
    }
  });

  const getSortField = field => {
    let result = field;
    if (field === 'date') {
      result = 'purchase_date';
    } else if(field === 'transaction') {
      result = 'amazon_order_id';
    } else if(field === 'product') {
      result = 'item_title';
    }

    return result;
  }

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    // const {ok, errors} = validate();
    // if (!ok) {
    //   setMessage(Object.values(errors).join(' '));
    //   setSeverity('error');
    //   setMessageShow(true);
    //   setIsLoading(false);
    //   return;
    // }
    getOrders(defaultSort, 0);
  }

  const handleFilterFormReset = (e) => {
    e.preventDefault();
    resetFilter();
  }

  const resetFilter = () => {
    setFilter({
      orderId: '',
      product: '',
      asin: '',
      status: '',
      purchaseDateFrom: null,
      purchaseDateTo: null
    });
  }

  const changeSort = (sortData) => {
    setSort(sortData);
    getOrders(sortData);
  }

  const handleNoteAdded = () => {
    getOrders();
  }

  const getUrl = (newSort = null, newPage = null) => {
    const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;
    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/get-amazon-orders?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getExportUrl = () => {
    let sortString = "sort=";
    sort.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    let url = `/export-amazon-orders?${sortString}`;

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getFilterString = () => {
    let filterString = '';
    const filterParams = [];

    if (search.length > 0) {
      return `search=${encodeURIComponent(search)}`;
    }

    if (filter.orderId.length > 0) {
      filterParams.push(`filter[order_id]=${filter.orderId}`);
    }

    if (filter.asin.length > 0) {
      filterParams.push(`filter[asin]=${filter.asin}`);
    }

    if (filter.status.length > 0) {
      filterParams.push(`filter[status]=${filter.status}`);
    }

    if (filter.product.length > 0) {
      filterParams.push(`filter[product]=${filter.product}`);
    }

    if (filter.purchaseDateFrom) {
      filterParams.push(`filter[purchaseDateFrom]=${moment(filter.purchaseDateFrom).format('YYYY-MM-DD')}`);
    }

    if (filter.purchaseDateTo) {
      filterParams.push(`filter[purchaseDateTo]=${moment(filter.purchaseDateTo).format('YYYY-MM-DD')}`);
    }

    if (filterParams.length > 0) {
      filterString = filterParams.join('&')
    }

    return filterString;
  }

  const handleFilterCollapse = () => setExpandedFilter(!expandedFilter);

  const getOrders = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then((response) => {
          setAmazonOrders(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 400) {
            const errors = err.response.data.errors;
            const msgs = errors.map(error => error.msg);
            setMessage(msgs.join(' '));
            setSeverity('error');
            setMessageShow(true);
          }
          console.log(err);
          console.log('Get amazon orders error');
        });
  }

  const columns = [
    { field: 'date', headerName: 'Date', width: 160, filterable: false, flex: 1,
      renderCell: (params) => {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
              <span><b>Purchase Date:</b></span>
              <span>{params.row.date.dateCreate}</span>
              <span><b>Last Update Date:</b></span>
              <span>{params.row.date.lastDateUpdate}</span>
            </div>
        );
      }
    },
    { field: 'transaction', headerName: 'Transaction ID', width: 200, filterable: false, sortable: true, flex: 1,
      renderCell: (params) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center", width: "100%"}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                <PersonIcon style={{color: "#ff9900"}} />
                <a style={{color: "#ff9900"}} href={`https://sellercentral.amazon.com/home?mons_sel_dir_mcid=amzn1.merchant.d.ACRW7T2LOYLPNSXUWVJYBNCNZYLQ&mons_sel_mkid=${params.row.transaction.marketplaceId}&mons_sel_dir_paid=amzn1.pa.d.ABK4MLPATKWJYSS2DR2SYXERRVFA&ignore_selection_changed=true`}>SIAS CORPORATION</a>
              </div>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <a href={`https://sellercentral.amazon.com/orders-v3/order/${params.row.transaction.id}`}>{params.row.transaction.id}</a>
              </div>
              <b>{params.row.transaction.source}</b>
            </div>
        );
      }
    },
    { field: 'buyer', headerName: 'Buyer', width: 175, filterable: false, sortable: false, flex: 1,
      renderCell: (params) => {
        const sendMessage = params.row.buyer.email ? <a style={{display: "flex", alignItems: "center"}} href={`https://sellercentral.amazon.com/messaging/contact?orderID=${params.row.buyer.id}&marketplaceID=${params.row.buyer.marketplaceId}`} target={'_blank'}>
          <ChatIcon style={{fontSize: '14px', marginRight: "4px"}}></ChatIcon>
          <span>send message</span>
        </a> : null;
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%"}}>
              {sendMessage}
            </div>
        );
      }},

    { field: 'product', headerName: 'Product', minWidth: 210, filterable: false, sortable: true, flex: 1,
      renderCell: (params) => {
        const image = params.row.product.image ? <div style={{width: '75px', display: 'inline-block', marginRight: '10px'}}><img src={params.row.product.image} width={'75px'}/></div> : null;
        const link = params.row.product.asin ? <div style={{display: 'inline-block', width: '210px', whiteSpace: 'pre-wrap', paddingRight: '10px'}}><a href={`https://www.amazon.com/gp/product/${params.row.product.asin}`} target={'_blank'}>{params.row.product.name}</a></div> : null;
        const ebayProduct = params.row.product.ebay_product_link ?
            <div>eBay Product: <a href={params.row.product.ebay_product_link} target={'_blank'}>{params.row.product.ebay_product_title}</a></div>
            : null;
        const ebayPartNumber = params.row.product.ebay_part_number ?
            <div>eBay Part Number: <strong>{params.row.product.ebay_part_number}</strong></div>
            : null;
        return (
            <div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {image}
                {link}
              </div>
              {ebayPartNumber}
              {ebayProduct}
            </div>
        );
      }
    },
    { field: 'asin', headerName: 'ASIN/SKU', width: 300, sortable: false, flex: 1,
      renderCell: params => {
        const asin = params.row.asin.asin ? <span>ASIN: <b>{params.row.asin.asin}</b></span> : null;
        const sku = params.row.asin.sku ? <span>SKU: <b>{params.row.asin.sku}</b></span> : null;
        const partNumber = params.row.asin.partNumber ? <span>Part Number: <b>{params.row.asin.partNumber}</b></span> : null;
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: "100%", justifyContent: "center"}}>
              {asin}
              {sku}
              {partNumber}
            </div>
        );
      }
    },
    { field: 'price', headerName: 'Price', width: 200, sortable: false, flex: 1,
      renderCell: params => {
        const shipping = params.row.price.shippingPrice > 0 ? <span>Shipping: {`$${params.row.price.shippingPrice.toFixed(2)}`}</span> : null;
        const shippingDiscount = params.row.price.shippingDiscount > 0 ? <span>Shipping Discount: {`-$${params.row.price.shippingDiscount.toFixed(2)}`}</span> : null;
        return (params.row.price.total > 0 ?
            <div style={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%"}}>
              <span>Price: {params.row.price.quantity} x ${params.row.price.price.toFixed(2)}</span>
              {shipping}
              {shippingDiscount}
              <span>Total: <b>${params.row.price.total.toFixed(2)}</b></span>
            </div> : <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>--/--</div>
        );
      }
    },
    { field: 'profit', headerName: 'Profit', width: 150, sortable: false, flex: 0.7,
      renderCell: params => {
        const profit = params.row.profit.profit >= 0 ? <b>${params.row.profit.profit.toFixed(2)}</b> : <b>-${Math.abs(params.row.profit.profit).toFixed(2)}</b>
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%'}}>
              <div>eBay: <b>${params.row.profit.ebayPrice.toFixed(2)}</b></div>
              <div>Charges: <b>${params.row.profit.charges.total ? params.row.profit.charges.total.toFixed(2) : 0.00}</b></div>
              <div>Profit: {profit}</div>
            </div>;
      }
    },
    { field: 'status', headerName: 'Status', width: 150, sortable: false, flex: 0.3,
      renderCell: (params) => {
        let icon = null;
        if (params.row.status.name === 'Shipped') {
          icon = <CheckCircleOutlineIcon style={{fontSize: "24px", color: "green"}}/>
        } else if (params.row.status.name === 'Unshipped') {
          icon = <LocalShippingIcon style={{fontSize: "24px", color: "red"}}/>
        } else if (params.row.status.name === 'Canceled') {
          icon = <CancelIcon style={{fontSize: "24px", color: "red"}}/>
        } else if (params.row.status.name === 'Pending') {
          icon = <PendingIcon style={{fontSize: "24px", color: "orange"}}/>
        } else if (params.row.status.name === 'Expedited') {
          icon = <LocalShippingIcon style={{fontSize: "24px", color: "limegreen"}}/>
        }
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
              <span>{icon}</span>
              <span><b>{params.row.status.name}</b></span><br/>
            </div>
        );
      }
    },
    { field: 'notes', headerName: 'Notes', width: 200, flex: 1,
      renderCell: (params) => {
        return <NotesPopup
            orderId={params.row.id}
            comments={params.row.notes}
            url={'add-amazon-order-comment'}
            handleNoteAdded={handleNoteAdded}
        />;
      }
    }
  ];

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    localStorage.setItem(AMAZON_ORDERS_FILTER, JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    localStorage.setItem(AMAZON_ORDERS_SORT, JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(AMAZON_ORDERS_SEARCH, JSON.stringify(search));
  }, [search]);

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    resetFilter();
    getOrders(defaultSort, 0);
  }

  const onPageChanged = page => {
    setPage(page);
    getOrders(null, page);
  }

  const iconFilter = expandedFilter
      ? <Badge badgeContent={filterCount()} color="success"><FilterAltOffIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>
      : <Badge badgeContent={filterCount()} color="success"><FilterAltIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>;

  return (
    <GridContainer>
      <SnackbarMessage
          open={messageShow}
          autoHideDuration={6000}
          setOpen={setMessageShow}
          severity={severity}
          message={message}
      />
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Amazon Orders</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "20px" }}>
            <Grid container direction={'row'}>
              <Grid item xs={2}>
                <div style={{marginTop: "9px"}}>
                  <a onClick={handleFilterCollapse}>
                    <span style={{display: "flex", alignItems: "center"}}>
                      {iconFilter}<DoubleArrowIcon style={{marginLeft: "-7px"}} />
                    </span>
                  </a>
                </div>
                <SwipeableDrawer
                    anchor={'left'}
                    open={expandedFilter}
                    onClose={toggleFilter(false)}
                    onOpen={toggleFilter(true)}
                >
                  <div style={{padding: "15px", maxWidth: "300px"}}>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                      <IconButton onClick={() => setExpandedFilter(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <h3 style={{marginTop: "10px"}}>Filter</h3>
                    <form
                        onSubmit={handleFilterFormSubmit}
                        onReset={handleFilterFormReset}
                    >
                      <Grid
                          container
                          alignItems="center"
                          justify="flex-start"
                          style={{ marginBottom: "30px" }}
                          spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                              id="order-id-input"
                              name="orderId"
                              type="text"
                              label={"Order Id"}
                              placeholder={'Order ID'}
                              value={filter.orderId}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="product-input"
                              name="product"
                              type="text"
                              label={"Product"}
                              placeholder={'Product'}
                              value={filter.product}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="asin-input"
                              name="asin"
                              type="text"
                              label={"ASIN"}
                              placeholder={'ASIN'}
                              value={filter.asin}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl style={{width: '100%'}}>
                            <InputLabel style={{backgroundColor: 'white'}}>Status</InputLabel>
                            <Select
                                displayEmpty
                                name="status"
                                value={filter.status}
                                onChange={handleFilterFieldChange}
                                style={{width: '100%'}}>
                              <MenuItem key="0" value="">None</MenuItem>
                              <MenuItem key="1" value="Pending">Pending</MenuItem>
                              <MenuItem key="2" value="Canceled">Canceled</MenuItem>
                              <MenuItem key="3" value="Shipped">Shipped</MenuItem>
                              <MenuItem key="4" value="Unshipped">Unshipped</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Controls.FormDatePicker
                              name="purchaseDateFrom"
                              label="Purchase Date From"
                              value={filter.purchaseDateFrom}
                              format={"dd.MM.yyyy"}
                              onChange={handleFilterFieldChange}
                              allowHelperText={false}
                              inputParams={{
                                fullWidth: true,
                              }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controls.FormDatePicker
                              name="purchaseDateTo"
                              label="Purchase Date To"
                              value={filter.purchaseDateTo}
                              format={"dd.MM.yyyy"}
                              onChange={handleFilterFieldChange}
                              allowHelperText={false}
                              inputParams={{
                                fullWidth: true,
                              }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight: "15px"}}
                            >
                              Apply
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="reset"
                            >
                              Reset
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </SwipeableDrawer>
              </Grid>
              <Grid item xs={8}>
                <div style={{width: '100%'}}>
                  <form onSubmit={handleSearchFormSubmit}>
                    <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        style={{ marginBottom: "15px", width: "100%", justifyContent: 'center'}}
                        spacing={2}
                    >
                      <Grid item xs={6}>
                        <FormControl style={{width: "100%"}}>
                          <TextField
                              id="search-input"
                              name="search"
                              type="text"
                              label={"Search"}
                              placeholder={'Search'}
                              value={search}
                              style={{width: "100%"}}
                              onChange={handleSearchChange}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.exportOrders}>
                  <AmazonOrdersExport
                      filter={filter}
                      exportUrl={getExportUrl()}
                  />
                </Box>
              </Grid>
            </Grid>
            <PureTableStyled
                tableHead={columns}
                tableData={amazonOrdersTableData}
                rowCount = {amazonOrders.total}
                pageSize = {pageSize}
                page = {page}
                setPage = {onPageChanged}
                onSortModelChange = {changeSort}
                isLoading = {isLoading}
                checkboxSelection = {false}
                sortModel={sort}
                components={{Footer: AmazonOrdersFooter}}
                componentsProps={{
                  footer: {
                    totalPrice: amazonOrders.amazonTotalPrice,
                    totalProfit: amazonOrders.amazonTotalProfit,
                    totalCharges: amazonOrders.amazonTotalCharges,
                  }
                }}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(AmazonOrders);
