import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import OrderDialog from "./OrderDialog";
import axios from '../../helpers/axios-api';
import {makeOrderId} from "../../helpers/common";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";

export default function EditOrder(props) {
    const {orderId, handleOrderEdited} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [data, setData] = useState({order: {}, orderItems: []});
    const [isLoading, setIsLoading] = useState(true);

    const getData = () => {
        setIsLoading(true);
        Promise.all([
            getStatuses().then(response => setStatuses(response.data.data)),
            getOrder().then(response => setData(response.data.data)),
        ]).then(() => {
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const getOrder = () => {
        return axios.get(`/get-ebay-order/${orderId}`);
    }

    const getStatuses = () => {
        return axios.get(`/get-ebay-order-item-statuses`);
    }

    const openModal = () => {
        getData();
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const initialFValues = {
        orderNumber: data.order.order_number ? data.order.order_number : makeOrderId(),
        purchaseDate: data.order.purchase_date ? moment(data.order.purchase_date, 'YYYY-MM-DD').toDate() : new Date(),
        buyer: data.order.buyer_info ? data.order.buyer_info : '',
        delivery: data.order.delivery_info ? data.order.delivery_info : '',
        status: data.order.status ? data.order.status : '',
    }

    const initialProducts = data.orderItems.map(orderItem => {
        const errors = {
            name: null,
            partNumber: null,
            link: null,
            quantity: '',
            price: null
        };

        return {
            id: orderItem.id ? orderItem.id : 0,
            name: orderItem.item_title ? orderItem.item_title : '',
            partNumber: orderItem.part_number_ordered ? orderItem.part_number_ordered : '',
            link: orderItem.item_link ? orderItem.item_link : '',
            quantity: orderItem.qty ? orderItem.qty : 0,
            price: orderItem.transaction_price ? orderItem.transaction_price : 0,
            errors
        }
    });

    const handleOrderFinished = () => {
        handleOrderEdited();
    }

    return (
        <Stack>
            <IconButton onClick={openModal} sx={{color: "#ff9900", fontSize: '38px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <EditIcon style={{color: "#ff9900"}}/>
                    <div style={{fontSize:'10px', color: "#ff9900"}}>Edit</div>
                </div>
            </IconButton>
            <OrderDialog
                formType={'edit'}
                orderId={orderId}
                modalTitle={"Edit Order"}
                closeModal={closeModal}
                modalOpen={modalOpen}
                statuses={statuses}
                isLoading={isLoading}
                handleOrderFinished={handleOrderFinished}
                initialFValues={initialFValues}
                initialProducts={initialProducts}
            />
        </Stack>
    );
}