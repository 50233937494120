import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import OrderDialog from "./OrderDialog";
import axios from '../../helpers/axios-api';
import {makeOrderId} from "../../helpers/common";

const initialFValues = {
    orderNumber: makeOrderId(),
    purchaseDate: new Date(),
    buyer: '',
    delivery: '',
    status: '',
}

export default function AddOrder(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getStatuses = () => {
        setIsLoading(true);
        axios.get(`/get-ebay-order-item-statuses`)
            .then(response => {
                setStatuses(response.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const openModal = () => {
        getStatuses();
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    return (
        <Stack>
            <Button variant="contained" color="primary" onClick={openModal}>Add new</Button>
            <OrderDialog
                formType={'add'}
                modalTitle={"Add New Order"}
                closeModal={closeModal}
                modalOpen={modalOpen}
                statuses={statuses}
                isLoading={isLoading}
                handleOrderFinished = {props.handleOrderAdded}
                initialFValues={initialFValues}
                initialProducts={[]}
            />
        </Stack>
    );
}