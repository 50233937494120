import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import axios from '../../helpers/axios-api';

export default function NotesPopup(props) {
    const { orderId, handleNoteAdded, comments } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [text, setText] = useState('');

    const openModal = () => {
        setModalOpen(true);
    }
    const closeModal = () => {
        setModalOpen(false);
    }

    let lastComment = null;
    if (comments.length > 0) {
        lastComment = <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
            <p style={{margin: 0}}><b>{comments[0].user_name}</b></p>
            <p style={{margin: 0}}>({moment(comments[0].created_at).format('DD.MM.YYYY HH:mm:ss')})</p>
            <p style={{margin: 0}}>{comments[0].text}</p>
        </div>
    }

    const createNote = (e) => {
        e.preventDefault();
        let type = 'order_id';
        if (props.hasOwnProperty('type')){
            type = props.type;
        }
        const request = {
            [type]: orderId,
            text: text
        }

        axios.post(`/${props.url}`, request).then((response) => {
            handleNoteAdded();
        });
    }

    return (
        <div style={{display:"flex", width: "100%"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
                <div>
                    {lastComment}
                </div>
                <IconButton onClick={openModal} sx={{color: "rgb(25 118 210)", fontSize: '24px', display: 'flex', flexDirection: 'column'}}>
                    <EditIcon />
                </IconButton>
            </div>
            <Dialog open={modalOpen} onClose={closeModal} maxWidth="l">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Add Note
                    <IconButton onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <form onSubmit={createNote}>
                            <textarea
                                style={{minWidth: "350px", minHeight: "150px"}}
                                value={text}
                                onChange={(event) => {
                                    setText(event.target.value);
                                }}
                            />
                            <Grid justify="center" container spacing={24}>
                                <Grid item>
                                    <Button onClick={closeModal} variant={'outlined'}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button type={"submit"} variant={'contained'}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}