import axios from 'axios';
import history from '../history';
const { REACT_APP_SERVER_URL } = process.env;

const instance = axios.create({
    baseURL: `${REACT_APP_SERVER_URL}`,
    headers: {
        common: {
            Accept: 'application/json',
        },
    },
});

instance.defaults.withCredentials = true

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log('response error interceptor', error);
        if (axios.isCancel(error)) {
            console.log(error.message);
            return Promise.reject(error);
        } else {
            if (error.response && error.response.status === 403) {
                history.push("/auth/login-page");
            }
            if (error.response && error.response.status !== 401) {
                console.log('response error', error);
            }
            return Promise.reject(error);
        }
    },
);

export default instance;
