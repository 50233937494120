import Input from "./Input";
import FormSelect from "./Select";
import FormDatePicker from "./DatePicker";
import ControlButton from "./Button";

const Controls = {
    Input,
    FormSelect,
    ControlButton,
    FormDatePicker
}

export default Controls;