// @material-ui/core components
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tableStyle from "assets/jss/siasapp-dashboard/components/tableStyle.jsx";
import {LinearProgress, TablePagination} from "@mui/material";
import {shallowEqualObject} from "../../helpers/common";

class PureTableStyled extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        };
    }

    shallowCompare(instance, nextProps, nextState) {
        if ((instance.props.isLoading === true) && (nextProps.isLoading === false)) {
            return true;
        }

        return !shallowEqualObject(instance.props.tableData, nextProps.tableData);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.shallowCompare(this, nextProps, nextState);
    }

    render () {
      const { classes,
          tableHead,
          tableData,
          tableHeaderColor,
          onSelectionModelChange,
          selectionModel,
          rowCount,
          pageSize,
          page,
          setPage,
          onSortModelChange,
          isLoading,
          sortModel,
          components,
          componentsProps
      } = this.props;
      const checkboxSelection = (Object.hasOwn(this.props, 'checkboxSelection') ? this.props.checkboxSelection : true);
      const customBackgroundRow = (Object.hasOwn(this.props, 'customBackgroundRow') ? this.props.customBackgroundRow : false);
      const StyledDataGrid = withStyles({
        root: {
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(159 197 248)"
            },
            "& .MuiDataGrid-footerContainer":{
                backgroundColor: "rgb(159 197 248)"
            },
            "& .MuiDataGrid-renderingZone": {
                maxHeight: "none !important"
            },
            "& .MuiDataGrid-virtualScrollerRenderZone": {
                maxHeight: "none !important",
                position: "static",
            },
            "& .MuiDataGrid-virtualScrollerContent": {
                maxHeight: "none !important",
                height: "max-content !important",
            },
            "& .MuiDataGrid-cell": {
                lineHeight: "unset !important",
                maxHeight: "none !important",
                whiteSpace: "pre !important",
                display: "flex !important",
                alignItems: "center",
                padding: "10px !important"
            },
            "& .MuiDataGrid-row": {
                maxHeight: "none !important"
            },
            "& .row--false": {
                backgroundColor: '#ff00001f'
            },
            "& .row--true": {
                backgroundColor: '#00800026'
            }
        }
    })(DataGrid);


      return <div style={{ height: 'max-content', width: '100%' }}>
        <StyledDataGrid
            autoHeight={true}
            disableVirtualization={true}
            autoPageSize={true}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectionModel}
            rows={this.props.tableData}
            columns={tableHead}
            rowsPerPageOptions={[2]}
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            rowCount={rowCount}
            sortingMode={"server"}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            loading={isLoading}
            getRowClassName={(params) => customBackgroundRow ? `row--${params.row.status}` : ''}
            components={components}
            componentsProps={componentsProps}
      />
    </div>
    }
}

export default withStyles(tableStyle)(PureTableStyled);
