import Button from "@mui/material/Button";
import React, {useEffect, useState, useRef} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import axios from '../../../helpers/axios-api';
import {
    IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FormSettings from "./FormSettings";
import withStyles from "@material-ui/core/styles/withStyles";
import SnackbarMessage from "../../../components/Common/SnackbarMessage";

export const defaultValues = {
    name: '',
    code: '',
    value: '',
};

const styles = {
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '15px',
        width: '100%',
    },
}

function AddSetting(props) {
    const {handleFinish, classes} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [messageShow, setMessageShow] = useState(false);
    const [message, setMessage] = useState('Something went wrong!');
    const [severity, setSeverity] = useState('error');

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const createSetting = async (values) => {
        const request = {
            name: values.name ? encodeURIComponent(values.name) : null,
            code: values.code ? encodeURIComponent(values.code) : null,
            value: values.value ? encodeURIComponent(values.value) : null,
        }

        axios.post(
            `/create-setting`,
            request
        ).then((response) => {
            setModalOpen(false);
            if (response.data.ok) {
                setMessage(response.data.message);
                setSeverity('success');
                setMessageShow(true);
                closeModal();
                handleFinish();
            } else {
                setMessage(response.data.message);
                setSeverity('error');
                setMessageShow(true);
            }
        });
    }

    return (
        <Stack spacing={1} direction="row">
            <div className={classes.actionButton}>
                <Button variant="contained" onClick={openModal}>Add New</Button>
            </div>
            <Dialog open={modalOpen} onClose={closeModal} fullWidth maxWidth="sm">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Add New Setting
                    <IconButton onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormSettings
                        initialFValues={defaultValues}
                        handleFinished={createSetting}
                    />
                </DialogContent>
            </Dialog>
            <SnackbarMessage
                open={messageShow}
                autoHideDuration={6000}
                setOpen={setMessageShow}
                severity={severity}
                message={message}
            />
        </Stack>
    );
}

export default withStyles(styles)(AddSetting);