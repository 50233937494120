import React from 'react'
import { FormHelperText } from '@material-ui/core';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

export default function FormSelect(props) {

    const { name, label, value, error = null, onChange, allowHelperText, options, ...other } = props;

    return (
        <FormControl
            variant="outlined"
            style={{width: '100%'}}
            {...(error && {error: true})}
        >
            <InputLabel style={{backgroundColor: 'white'}}>{label}</InputLabel>
            <Select
                name={name}
                value={value}
                onChange={onChange}
                {...other}
            >
                <MenuItem key={"none"} value="">None</MenuItem>
                    {
                        options.map(
                            (item, index) => (<MenuItem key={index} value={item.value}>{item.name}</MenuItem>)
                        )
                    }
            </Select>
            {error && allowHelperText && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
