import React, {useState, useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Alert, CircularProgress, Dialog, DialogContent, Tooltip} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Input from "@material-ui/core/Input";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import ClearIcon from '@mui/icons-material/Clear';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from '../../helpers/axios-api';
import moment from "moment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const productStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    borderBottom: '1px black dashed',
    paddingBottom: '15px'
}

const CustomTableCell = ({ row, name, onChange, index }) => {
    return (
        <TableCell align="left">
            <Input
                value={row[name]}
                name={name}
                onChange={e => onChange(e, row, index)}
            />
        </TableCell>
    );
};

export default function InventoryPopup(props) {

    const price = 0;
    const { product, partNumber } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [warehouseStocks, setWarehouseStocks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewedAlertUpdated, setViewedAlertUpdated] = useState(false);
    const [viewedAlertCreated, setViewedAlertCreated] = useState(false);

    const getWarehouseStocks = () => {
        setIsLoading(true);
        axios.get(`/get-warehouse-stocks-for-inventory/${partNumber}`)
            .then((response) => {
                setWarehouseStocks(response.data.data.map(warehouseStock => { return {
                    id: warehouseStock.id,
                    date: warehouseStock.purchase_date ? moment(warehouseStock.purchase_date).format('DD.MM.YYYY') : null,
                    orderId: warehouseStock.ebay_order_id,
                    partNumber: warehouseStock.part_number,
                    quantity: warehouseStock.qty,
                    price: warehouseStock.price,
                    amazonAsin: warehouseStock.amazon_asin,
                }}));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
                console.log('Get warehouse stocks error');
            });

    }

    const openPopup = () => {
        setIsOpen(true);
        getWarehouseStocks();
    }

    const closePopup = () => {
        setIsOpen(false);
        setWarehouseStocks([]);
        setRows([]);
    }

    const createData = function (partNumber, quantity, price) {
        let date = moment().format('YYYY-MM-DD');
        return {
            date,
            partNumber,
            quantity,
            price
        }
    };

    const onChange = (e, row, changeIndex) => {
        const value = e.target.value;
        const name = e.target.name;
        const newRows = rows.map((row, index) => {
            if (changeIndex === index) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setRows(newRows);
    }

    const onChangeDate = (value, name, row, changeIndex) => {
        const date = moment(value).format('YYYY-MM-DD');
        const newRows = rows.map((row, index) => {
            if (changeIndex === index) {
                return { ...row, [name]: date };
            }
            return row;
        });
        setRows(newRows);
    }

    const onChangeWarehouseStock = (e, row, changeIndex) => {
        const value = e.target.value;
        const name = e.target.name;
        const newWarehouseStocks = warehouseStocks.map((row, index) => {
            if (changeIndex === index) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setWarehouseStocks(newWarehouseStocks);
    }

    const addRow = () => {
        let newRow = createData(partNumber, '1', price);
        let newRows = [...rows];
        newRows.push(newRow);
        setRows(newRows);
    }

    const removeRow = (index) => () => {
        let newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    }

    const handleWarehouseStocks = (e) => {
        e.preventDefault();
        const createRequest = rows.map(row => {
            return {
                title: encodeURIComponent(product.title),
                link: encodeURIComponent(product.link),
                picture: encodeURIComponent(product.picture),
                purchaseDate: row.date,
                partNumber: row.partNumber,
                price: row.price,
                quantity: row.quantity,
            }
        });

        const updateRequest = warehouseStocks.map(warehouseStock => {
            return {
                id: warehouseStock.id,
                price: warehouseStock.price,
                quantity: warehouseStock.quantity,
            }
        });

        axios.patch(
            `/update-inventory-warehouse-stocks`,
            {warehouseStocks: updateRequest}
        ).then((response) => {
            if (response.data.ok) {
                setViewedAlertUpdated(true);
            }
        }).catch(err => {
            console.log(err);
            console.log('Update warehouse stocks error');
        });

        if (createRequest.length > 0) {
            axios.post(
                `/create-inventory-warehouse-stocks`,
                {warehouseStocks: createRequest}
            ).then((response) => {
                if (response.data.ok) {
                    setViewedAlertCreated(true);
                }
            }).catch(err => {
                console.log(err);
                console.log('Create warehouse stocks error');
            });
        }
    }

    return (
        <div>
            <Tooltip title={'Inventory'}>
                <div>
                    <IconButton onClick={openPopup} style={{
                        fontSize: '24px'
                    }} >
                        <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
                            <AllInboxRoundedIcon style={{color: "rgb(25 118 210)"}} />
                        </div>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog open={isOpen} fullWidth maxWidth="md">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Inventory
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={productStyle}>
                        <img src={product.picture} style={{maxWidth: '100px', height: 'auto', marginRight: '20px'}} />
                        <span style={{fontSize: '16px', fontWeight: 700}}>{product.title}</span>
                    </div>
                    {viewedAlertUpdated ? <Alert
                        style={{marginBottom: '15px', display: 'flex', alignItems: 'center', fontSize: '1rem'}}
                        severity={'success'}
                        action={
                            <IconButton
                                aria-label={'close'}
                                color={'inherit'}
                                size={'small'}
                                onClick={() => setViewedAlertUpdated(false)}
                            >
                                <CloseIcon fontSize={'inherit'} />
                            </IconButton>
                        }
                    >Warehouse stocks have been updated!</Alert> : <></> }
                    {isLoading && <Box>
                        <CircularProgress color="secondary" />
                    </Box>}
                    {!isLoading && <TableContainer component={Paper} style={{marginBottom: '20px'}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Order ID</TableCell>
                                    <TableCell align="center">Part Number</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Price</TableCell>
                                    <TableCell align="center">Amazon</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {warehouseStocks.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.date}</TableCell>
                                        <TableCell component="th" scope="row">{
                                            row.orderId ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                <b>eBay</b>
                                                <a href={`https://www.ebay.com/vod/FetchOrderDetails?orderId=${row.orderId}`}>{row.orderId}</a><br/>
                                            </div> : <></>}
                                        </TableCell>
                                        <TableCell component="th" scope="row">{row.partNumber}</TableCell>
                                        <CustomTableCell {...{ row, name: "quantity", onChange: onChangeWarehouseStock, index }} />
                                        <CustomTableCell {...{ row, name: "price", onChange: onChangeWarehouseStock, index }} />
                                        <TableCell align="center">
                                            {
                                                row.amazonAsin ? <a href={'#'}>{row.amazonAsin}</a> : <span>--/--</span>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {viewedAlertCreated ? <Alert
                        style={{marginBottom: '15px', display: 'flex', alignItems: 'center', fontSize: '1rem'}}
                        severity={'success'}
                        action={
                            <IconButton
                                aria-label={'close'}
                                color={'inherit'}
                                size={'small'}
                                onClick={() => setViewedAlertCreated(false)}
                            >
                                <CloseIcon fontSize={'inherit'} />
                            </IconButton>
                        }
                    >Warehouse stocks have been created!</Alert> : <></> }
                    {rows.length > 0 && <TableContainer component={Paper} style={{marginBottom: '15px'}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Order ID</TableCell>
                                    <TableCell align="center">Part Number</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Price</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date"
                                                    inputFormat="dd.MM.yyyy"
                                                    value={row.date}
                                                    onChange={value => onChangeDate(value, 'date', row, index)}
                                                    name={'date'}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>
                                        <TableCell component="th" scope="row"></TableCell>
                                        <TableCell align="left">
                                            <Input
                                                required={true}
                                                value={row.partNumber}
                                                name={'partNumber'}
                                                onChange={e => onChange(e, row, index)}
                                            />
                                        </TableCell>
                                        <CustomTableCell {...{ row, name: "quantity", onChange, index }} />
                                        <CustomTableCell {...{ row, name: "price", onChange, index }} />
                                        <TableCell align="right">
                                            <IconButton onClick={removeRow(index)} style={{color:'red', fontSize: '17px'}}>
                                                <ClearIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    <Grid justify="space-between" direction="row-reverse" container spacing={24}>
                        <Grid item>
                            <Button onClick={addRow} variant={'contained'}>
                                Add Product
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid justify="center" container spacing={24}>
                        <Grid item>
                            <Button onClick={closePopup} variant={'outlined'}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleWarehouseStocks} variant={'contained'}>Save</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}