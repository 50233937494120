import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  resultsStyle: {
    fontSize: '20px',
    lineHeight: '1',
  }
};

const EbaySearchResultsCell = props => {
  const { resultsCount, newResultsCount, searchId, classes } = props;

  let results = <span className={classes.resultsStyle}>0</span>;
  let resultsLink = null;
  if (resultsCount > 0) {
    resultsLink = <a href={`/admin/ebay-results?search=${searchId}`} target={"_blank"}>{resultsCount}</a>
  }
  let newResultsLink = null;
  if (newResultsCount > 0) {
    newResultsLink = <a style={{ color: "green" }} target={"_blank"} href={`/admin/ebay-results?search=${searchId}&is_new=1`}>{newResultsCount}</a>
  }
  if (resultsLink && newResultsLink) {
    results = <span className={classes.resultsStyle}>{resultsLink} / {newResultsLink}</span>;
  } else if(resultsLink) {
    results = <span className={classes.resultsStyle}>{resultsLink}</span>;
  }

  return results;
}

export default withStyles(styles)(EbaySearchResultsCell);
