import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import OrderDialogProduct from "./OrderDialogProduct";

const styles = {
    gridItem: {},
    textHelper: {
        fontSize: '10px',
    },
    formInput: {
        marginTop: '8px'
    },
    orderDialogProducts: {
        marginTop: '15px',
        width: "100%"
    },
    addButton: {
        display: 'flex',
        justifyContent: "flex-end",
        width: '100%'
    },
    productsContainer: {
        marginBottom: '20px',
        marginTop: '15px'
    }
}

const boxStyle = {
    marginBottom: "15px"
}

function OrderDialogProducts(props) {

    const { rows, removeRow, addRow, handleRowInputChange, classes } = props;

    return(<div className={classes.orderDialogProducts}>
            <Grid justify="space-between" direction="row-reverse" container spacing={24}>
                <Grid item xs={12}>
                    <div className={classes.addButton}>
                        <Button onClick={addRow} variant={'contained'}>
                            Add Product
                        </Button>
                    </div>
                </Grid>
            </Grid>
            {rows.length > 0 && <TableContainer component={Paper} className={classes.productsContainer}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Link</TableCell>
                            <TableCell>Part Number</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <OrderDialogProduct
                                key={index}
                                index={index}
                                row={row}
                                removeRow={removeRow}
                                handleRowInputChange={handleRowInputChange}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    );
}

export default withStyles(styles)(OrderDialogProducts);