import React, {useEffect, useState} from "react";

import axios from '../../helpers/axios-api';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Badge, Collapse, Grid, IconButton, Select, SwipeableDrawer} from "@mui/material";
import {getOffset} from "../../helpers/common";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentIcon from '@mui/icons-material/Payment';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptPopup from "../../components/Popups/ReceiptPopup";
import AddOrder from "../../components/Popups/AddOrder";
import EditOrder from "../../components/Popups/EditOrder";
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import NotesPopup from "../../components/Popups/NotesPopup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import DirectionsIcon from '@mui/icons-material/Directions';
import HtmlPopup from "../../components/Popups/HtmlPopup";
import EbayOrdersFooter from "./components/EbayOrdersFooter";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  greyText: {
    color: 'grey',
    opacity: '50%',
    "&:hover": {
      color: 'grey',
      opacity: '100%'
    }
  }
};

const pageSize = 20;
const defaultSort = [{ field: 'date', sort: 'desc'}];
const defaultFilter = {
  orderId: '',
  product: '',
  status: '',
  partNumber: '',
  priceFrom: '',
  priceTo: '',
  trackNumber: ''
};

const EBAY_ORDERS_FILTER = 'EBAY_ORDERS_FILTER';
const EBAY_ORDERS_SORT = 'EBAY_ORDERS_SORT';
const EBAY_ORDERS_SEARCH = 'EBAY_ORDERS_SEARCH';

function EbayOrder(props) {
  const { classes } = props;
  const savedFilter = JSON.parse(localStorage.getItem(EBAY_ORDERS_FILTER));
  const savedSort = JSON.parse(localStorage.getItem(EBAY_ORDERS_SORT));
  const savedSearch = JSON.parse(localStorage.getItem(EBAY_ORDERS_SEARCH));
  const initialFilter = savedFilter || defaultFilter;
  const initialSort = savedSort || defaultSort;
  const initialSearch = savedSearch || '';
  const [eBayOrders, setEbayOrders] = useState({data: [], total: 0, totalPrice: 0, totalShipping: 0});
  const [filter, setFilter] = useState(initialFilter);
  const [page, setPage] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [sort, setSort] = useState(initialSort);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [search, setSearch] = useState(initialSearch);

  const handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const filterCount = () => {
    let count = 0;
    Object.keys(filter).map(function(objectKey, index) {
      if (filter[objectKey]) count++;
    });
    return count;
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }

  const eBayOrdersTableData = eBayOrders.data.map((eBayOrder, index) => {
    return {
      id: index,
      key: index,
      date: {
        create: {
          date: eBayOrder.purchase_date ? moment(eBayOrder.purchase_date).format('DD.MM.YYYY') : null,
          user: eBayOrder.ebay_account_used_to_buy
        },
        update: {
          date: eBayOrder.updated_at ? moment(eBayOrder.updated_at).format('DD.MM.YYYY HH:mm:ss') : null,
          user: eBayOrder.ebay_account_used_to_buy
        }
      },
      order: {
        id: eBayOrder.order_id,
        orderId: eBayOrder.id,
        user: eBayOrder.buyer_id ? eBayOrder.buyer_id : null,
        source: !!eBayOrder.is_manual ? 'manual' : 'eBay',
        buyerInfo: eBayOrder.buyer_info ? eBayOrder.buyer_info : null,
        isManual: !!eBayOrder.is_manual
      },
      seller: {
        name: eBayOrder.seller_account_name,
        link: eBayOrder.item_link,
        buyerInfo: eBayOrder.buyer_info ? eBayOrder.buyer_info : null,
        isManual: !!eBayOrder.is_manual,
      },
      product: {
        image: eBayOrder.item_picture_url && eBayOrder.item_picture_url.length > 0 ? eBayOrder.item_picture_url[0] : null,
        name: eBayOrder.item_title,
        link: eBayOrder.item_link,
        description: eBayOrder.item_description ? eBayOrder.item_description : null,
        is_manual: !!eBayOrder.is_manual,
      },
      partNumber: eBayOrder.part_number_ordered ? eBayOrder.part_number_ordered : '',
      price: {
        count: eBayOrder.qty,
        receiptCost: eBayOrder.receipt_cost ? eBayOrder.receipt_cost : 0.00,
        price: eBayOrder.transaction_price,
        shipping: eBayOrder.actual_shipping_cost_value ? parseFloat(eBayOrder.actual_shipping_cost_value, 10) : 0,
        total: eBayOrder.total_paid
      },
      shipping: {
        company: eBayOrder.shipping_details_carrier ? eBayOrder.shipping_details_carrier : null,
        location: eBayOrder.shipping_address_city_name && eBayOrder.shipping_address_state ? `${eBayOrder.shipping_address_city_name}, ${eBayOrder.shipping_address_state}` : null,
        itemId: eBayOrder.ebay_item_number ? eBayOrder.ebay_item_number : null,
        trackId: eBayOrder.shipping_details_tracking_number ? eBayOrder.shipping_details_tracking_number : null,
        transactionId: eBayOrder.transaction_id ? eBayOrder.transaction_id : null,
        cost: eBayOrder.actual_shipping_cost_value ? parseFloat(eBayOrder.actual_shipping_cost_value, 10) : 0,
        status: eBayOrder.status ? eBayOrder.status : null,
        deliveryInfo: eBayOrder.delivery_info ? eBayOrder.delivery_info : null,
        isManual: !!eBayOrder.is_manual
      },
      status: {
        name: eBayOrder.status ? eBayOrder.status : null,
        createdAt: eBayOrder.created_at ? eBayOrder.created_at  : null,
        updatedAt: eBayOrder.updated_at ? eBayOrder.updated_at : null,
        transactionActualDeliveryTime: eBayOrder.transaction_actual_delivery_time ? eBayOrder.transaction_actual_delivery_time : null,
        transactionShippedTime: eBayOrder.transaction_shipped_time ? eBayOrder.transaction_shipped_time : null,
        orderPaidTime: eBayOrder.order_paid_time ? eBayOrder.order_paid_time : null,
        purchaseDate: eBayOrder.purchase_date ? eBayOrder.purchase_date : null,
      },
      actions: {
        orderId: eBayOrder.id,
      },
      comments: eBayOrder.comments ? eBayOrder.comments : [],
      is_manual: !!eBayOrder.is_manual
    }
  });

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    getOrders(defaultSort, 0);
  }

  const handleFilterFormReset = (e) => {
    e.preventDefault();
    resetFilter();
  }

  const resetFilter = () => {
    setFilter({
      orderId: '',
      product: '',
      partNumber: '',
      status: '',
      priceFrom: '',
      priceTo: '',
      trackNumber: ''
    });
  }

  const getSortField = field => {
    let result = field;
    if (field === 'date') {
      result = 'purchase_date';
    } else if(field === 'order') {
      result = 'order_id';
    }

    return result;
  }

  const changeSort = (sortData) => {
    setSort(sortData);
    getOrders(sortData);
  }

  const handleNoteAdded = () => {
    getOrders();
  }

  const getUrl = (newSort = null, newPage = null) => {

    const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;
    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/get-ebay-orders?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getFilterString = () => {
    let filterString = '';
    const filterParams = [];

    if (search.length > 0) {
      return `search=${encodeURIComponent(search)}`;
    }

    if (filter.orderId.length > 0) {
      filterParams.push(`filter[order_id]=${filter.orderId}`);
    }

    if (filter.partNumber.length > 0) {
      filterParams.push(`filter[part_number_ordered]=${filter.partNumber}`);
    }

    if (filter.product.length > 0) {
      filterParams.push(`filter[item_title]=${filter.product}`);
    }

    if (filter.status.toString().length > 0) {
      filterParams.push(`filter[status]=${filter.status}`);
    }

    if (filter.priceFrom.toString().length > 0) {
      filterParams.push(`filter[price_from]=${filter.priceFrom}`);
    }

    if (filter.priceTo.toString().length > 0) {
      filterParams.push(`filter[price_to]=${filter.priceTo}`);
    }

    if (filter.trackNumber && filter.trackNumber.toString().length > 0) {
      filterParams.push(`filter[track_number]=${filter.trackNumber}`);
    }

    if (filterParams.length > 0) {
      filterString = filterParams.join('&')
    }

    return filterString;
  }

  const handleFilterCollapse = () => setExpandedFilter(!expandedFilter);

  const handleOrderFinished = () => {
    getOrders();
  }

  const getOrders = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then(ebayOrders => {
          setEbayOrders(ebayOrders.data);
          setIsLoading(false);
        }).catch((err) => {
          console.log(err);
          console.log('Get orders error');
          setIsLoading(false);
    })
  }

  const columns = [
    { field: 'date', headerName: 'Date', minWidth: 70, filterable: false, flex: 0.5,
      renderCell: (params) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <span><strong>Purchase Date:</strong></span>
              <span>{params.row.date.create.date}</span>
              <span><strong>Last Update:</strong></span>
              <span>{params.row.date.update.date}</span>
            </div>
        );
      }
    },
    { field: 'order', headerName: 'Order ID', minWidth: 130, filterable: false, flex: 1,
      renderCell: (params) => {
        let buyer = null;
        if (!params.row.shipping.isManual) {
          buyer = params.row.order.user ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <PersonIcon style={{color: "#ff9900"}} />
            <a style={{color: "#ff9900"}} target={"_blank"} href={`https://www.ebay.com/mye/myebay/summary`}>{params.row.order.user}</a>
          </div> : null;
        }
        if (params.row.shipping.isManual) {
          buyer = params.row.order.buyerInfo ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <PersonIcon style={{color: "#ff9900"}} />
            <span style={{color: "#ff9900"}}>{params.row.order.buyerInfo}</span>
          </div> : null;
        }
        const orderId = !params.row.order.isManual ? <a target={"_blank"} href={`https://www.ebay.com/vod/FetchOrderDetails?orderId=${params.row.order.id}`}>{params.row.order.id}</a> :
            <span>{params.row.order.id}</span>;
        return (<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              {buyer}
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                {orderId}<br/>
                <b>{params.row.order.source}</b>
              </div>
            </div>
        );
      }
    },
    { field: 'seller', headerName: 'Seller', minWidth: 80, filterable: false, sortable: false, flex: 1,
      renderCell: (params) => {
        const seller = !params.row.seller.isManual ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <a href={`https://www.ebay.com/usr/${params.row.seller.name}`}><b>{params.row.seller.name}</b></a><br/>
          <a style={{display:'inline'}} className={classes.greyText} href={params.row.seller.link} target={'_blank'}><ChatIcon style={{fontSize: '14px', verticalAlign: 'middle'}}></ChatIcon><span style={{verticalAlign: 'middle'}}>Send message</span></a><br/>
        </div> : '';
        return seller;
      }
    },
    { field: 'product', headerName: 'Product', minWidth: 250, filterable: false, sortable: false, flex: 1,
      renderCell: (params) => {
        const descriptionPopup = params.row.product.description ? <HtmlPopup html={params.row.product.description} style={{position: 'absolute', top: 0, right: 0}}>
          {params.row.product.description}
        </HtmlPopup> : null;
        const image = !params.row.product.is_manual ? <div style={{width: '75px', display: 'inline-block', marginRight: '10px'}}><img src={params.row.product.image} width={'75px'}/></div> : null;
        return (
            <div style={{display: 'flex', alignItems: 'center', position: 'relative', width: '100%'}}>
              {image}
              <div style={{display: 'inline-block', whiteSpace: "pre-wrap", width: '210px'}}>
                <a href={params.row.product.link} target={'_blank'}>{params.row.product.name}</a>
              </div>
              {descriptionPopup}
            </div>
        );
      }
    },
    { field: 'partNumber', headerName: 'Part Number', width: 125, filterable: false, sortable: false, flex: 1},
    { field: 'price', headerName: 'Price', minWidth:80, sortable: false, flex: 1,
      renderCell: (params) => {
        const shipping = params.row.price.shipping ? <span style={{display: 'block'}}>Shipping: <b>{`$${params.row.price.shipping}`}</b></span> : null;
        return (
            <div>
              <span style={{display: 'block'}}>{params.row.price.count} x {`$${params.row.price.price}`}</span>
              {shipping}
              <span style={{display: 'block'}}>Total: <b>{`$${params.row.price.total}`}</b></span>
            </div>
        );
      }
    },
    { field: 'shipping', headerName: 'Shipping', minWidth: 130, sortable: false, flex: 1,
      renderCell: (params) => {
        const trackingLink = params.row.shipping.trackId ? <p style={{textAlign: "center", margin: 0}}>
          <a href={`https://www.ebay.com/ship/trk/tracking-details?transid=${params.row.shipping.transactionId}&itemid=${params.row.shipping.itemId}`}>{params.row.shipping.trackId}</a>
        </p> : null;
        let shipping = null;
        if (params.row.shipping.cost > 0) {
          shipping = <p style={{textAlign: "center", margin: 0}}>
            <b>{`$${params.row.shipping.cost}`}</b>
          </p>
        }
        const shippingInfo = !params.row.shipping.isManual ? <div>
          {params.row.shipping.company ? <p style={{textAlign: "center", margin: 0}}><b>{params.row.shipping.company}</b></p> : null}
          {params.row.shipping.location ? <p style={{textAlign: "center", margin: 0}}>{params.row.shipping.location}</p> : null}
          {trackingLink}
          {shipping}
        </div> :
        <div>
          <span>{params.row.shipping.deliveryInfo}</span>
        </div>;
        return shippingInfo;
      }
    },
    { field: 'status', headerName: 'Status', width: 150, sortable: false, flex: 1,
      renderCell: (params) => {
        let icon = null;
        let date = null;
        if (params.row.status.name === 'Active') {
          icon = <DirectionsIcon style={{fontSize: "24px", color: "orange"}}/>
          date = params.row.status.purchaseDate ? moment(params.row.status.purchaseDate).format('DD.MM.YYYY') : null;
        } else if (params.row.status.name === 'Shipped') {
          icon = <LocalShippingIcon style={{fontSize: "24px", color: "limegreen"}}/>
          date = params.row.status.transactionShippedTime ? moment(params.row.status.transactionShippedTime).format('DD.MM.YYYY') : null;
        } else if (params.row.status.name === 'Cancelled') {
          icon = <CancelIcon style={{fontSize: "24px", color: "red"}}/>
          date = params.row.status.updatedAt ? moment(params.row.status.updatedAt).format('DD.MM.YYYY') : null;
        } else if (params.row.status.name === 'Cancelling') {
          icon = <CancelIcon style={{fontSize: "24px", color: "orange"}}/>
          date = params.row.status.updatedAt ? moment(params.row.status.updatedAt).format('DD.MM.YYYY') : null;
        } else if (params.row.status.name === 'Paid') {
          icon = <PaymentIcon style={{fontSize: "24px", color: "orange"}}/>
          date = params.row.status.orderPaidTime ? moment(params.row.status.orderPaidTime).format('DD.MM.YYYY') : null;
        } else if (params.row.status.name === 'Delivered') {
          icon = <CheckCircleOutlineIcon style={{fontSize: "24px", color: "green"}}/>
          date = params.row.status.transactionActualDeliveryTime ? moment(params.row.status.transactionActualDeliveryTime).format('DD.MM.YYYY') : null;
        } else if (params.row.status.name === 'Completed') {
          icon = <CheckCircleOutlineIcon style={{fontSize: "24px", color: "green"}}/>;
          date = params.row.status.updatedAt ? moment(params.row.status.updatedAt).format('DD.MM.YYYY') : null;
        }

        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
              <span>{icon}</span>
              <span><b>{params.row.status.name}</b></span><br/>
              <span>{date}</span>
            </div>
        );
      }
    },
    { field: 'comments', headerName: 'Notes', width: 250, sortable: false, flex: 1,
      renderCell: (params) => {
        return <NotesPopup
            orderId={params.row.id}
            comments={params.row.comments}
            url={'add-ebay-order-comment'}
            handleNoteAdded={handleNoteAdded}
        />;
      }
    },
    { field: 'actions', headerName: '', width: 100, filterable: false, sortable: false, flex: 0.2,
      renderCell: (params) => {
        let action = params.row.order.isManual ? <EditOrder
            orderId={params.row.actions.orderId}
            handleOrderEdited={handleOrderFinished}
        /> : <ReceiptPopup
            key={params.row.id}
            partNumber={params.row.partNumber}
            orderId={params.row.order.orderId}
            ebayOrderId={params.row.order.id}
            quantity={params.row.price.count}
            price={params.row.price.receiptCost}
            image={params.row.product.image}
            name={params.row.product.name}
          />

        return action;
      }
    }
  ];

  const toggleFilter = expandedFilter =>
      event => {
        if (
            event &&
            (event.type === 'keydown'
                && event.keyCode === 'Tab' ||
                event.keyCode === 'Shift')
        ) {
          return;
        }

        setExpandedFilter(expandedFilter);
      };

  const getStatuses = () => {
    return axios.get(`/get-ebay-order-item-statuses`)
  }

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getStatuses().then(response => setStatuses(response.data.data)),
    ]).then(() => {
      getOrders();
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(EBAY_ORDERS_FILTER, JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    localStorage.setItem(EBAY_ORDERS_SORT, JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(EBAY_ORDERS_SEARCH, JSON.stringify(search));
  }, [search]);

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    getOrders(defaultSort, 0);
  }

  const onPageChanged = page => {
    setPage(page);
    getOrders(null, page);
  }

  const iconFilter = expandedFilter
      ? <Badge badgeContent={filterCount()} color="success"><FilterAltOffIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>
      : <Badge badgeContent={filterCount()} color="success"><FilterAltIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>;

  const statusesOptions = statuses.map((status, index) =>
      <MenuItem key={index} value={status.value}>
        {status.name}
      </MenuItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>eBay Orders</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "20px" }}>
            <Stack direction={'row'} alignItems="center" style={{ marginBottom: "15px" }}>
            <div>
              <a onClick={handleFilterCollapse}>
                <span style={{display: "flex", alignItems: "center"}}>
                  {iconFilter}<DoubleArrowIcon style={{marginLeft: "-7px"}} />
                </span>
                </a>
              </div>
              <SwipeableDrawer
                  anchor={'left'}
                  open={expandedFilter}
                  onClose={toggleFilter(false)}
                  onOpen={toggleFilter(true)}
              >
                <div style={{padding: "15px", maxWidth: "300px"}}>
                  <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <IconButton onClick={() => setExpandedFilter(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <h3 style={{marginTop: "10px"}}>Filter</h3>
                  <form
                      onSubmit={handleFilterFormSubmit}
                      onReset={handleFilterFormReset}
                  >
                    <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        style={{ marginBottom: "30px" }}
                        spacing={2}
                    >
                      <Grid item xs={12}>
                        <TextField
                            id="order-id-input"
                            name="orderId"
                            type="text"
                            label={"Order Id"}
                            placeholder={'Order ID'}
                            value={filter.orderId}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="product-input"
                            name="product"
                            type="text"
                            label={"Product"}
                            placeholder={'Product'}
                            value={filter.product}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      {/*<Grid item xs={6}>*/}
                      {/*  <TextField*/}
                      {/*      id="price-from-input"*/}
                      {/*      name="priceFrom"*/}
                      {/*      type="number"*/}
                      {/*      label={"Price from"}*/}
                      {/*      placeholder={'Price from'}*/}
                      {/*      value={filter.priceFrom}*/}
                      {/*      style={{width: "100%"}}*/}
                      {/*      onChange={handleFilterFieldChange}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={6}>*/}
                      {/*  <TextField*/}
                      {/*      id="price-to-input"*/}
                      {/*      name="priceTo"*/}
                      {/*      type="number"*/}
                      {/*      label={"Price to"}*/}
                      {/*      placeholder={'Price to'}*/}
                      {/*      value={filter.priceTo}*/}
                      {/*      style={{width: "100%"}}*/}
                      {/*      onChange={handleFilterFieldChange}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item xs={12}>
                        <TextField
                            id="part-number-input"
                            name="partNumber"
                            type="text"
                            label={"Part Number"}
                            placeholder={'Part Number'}
                            value={filter.partNumber}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="track-number-input"
                            name="trackNumber"
                            type="text"
                            label={"Track Number"}
                            placeholder={'Track Number'}
                            value={filter.trackNumber}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl style={{width: '100%'}}>
                          <InputLabel style={{backgroundColor: 'white'}}>Status</InputLabel>
                          <Select
                              displayEmpty
                              name="status"
                              value={filter.status}
                              onChange={handleFilterFieldChange}
                              style={{width: '100%'}}>
                            <MenuItem key="none" value="">None</MenuItem>
                            {statusesOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                          <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{marginRight: "15px"}}
                          >
                            Apply
                          </Button>
                          <Button
                              variant="outlined"
                              color="primary"
                              type="reset"
                          >
                            Reset
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </SwipeableDrawer>
              <div style={{width: '100%'}}>
                <form onSubmit={handleSearchFormSubmit}>
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                      style={{ width: "100%", justifyContent: 'center'}}
                      spacing={2}
                  >
                    <Grid item xs={6}>
                      <FormControl style={{width: "100%"}}>
                        <TextField
                            id="search-input"
                            name="search"
                            type="text"
                            label={"Search"}
                            placeholder={'Search'}
                            value={search}
                            style={{width: "100%"}}
                            onChange={handleSearchChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl style={{width: '100%'}}>
                        <InputLabel style={{backgroundColor: 'white'}}>Status</InputLabel>
                        <Select
                            displayEmpty
                            name="status"
                            value={filter.status}
                            onChange={handleFilterFieldChange}
                            style={{width: '100%'}}>
                          <MenuItem key="none" value="">None</MenuItem>
                          {statusesOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div style={{minWidth: "114px"}}>
                <AddOrder handleOrderAdded={handleOrderFinished} />
              </div>
            </Stack>
            <PureTableStyled
              tableHead={columns}
              tableData={eBayOrdersTableData}
              rowCount = {eBayOrders.total}
              pageSize = {pageSize}
              page = {page}
              setPage = {onPageChanged}
              onSortModelChange = {changeSort}
              isLoading = {isLoading}
              checkboxSelection = {false}
              sortModel={sort}
              components={{Footer: EbayOrdersFooter}}
              componentsProps={{
                footer: {
                  totalPrice: eBayOrders.totalPrice,
                  totalShipping: eBayOrders.totalShipping
                }
              }}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(EbayOrder);
