import { createSlice } from '@reduxjs/toolkit'

export const alertEbaySearchResultsSlice = createSlice({
    name: 'alertEbaySearchResults',
    initialState: {
        value: false,
    },
    reducers: {
        setAlertEbaySearchResults: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setAlertEbaySearchResults } = alertEbaySearchResultsSlice.actions;

export const selectAlertEbaySearchResults = state => state.alertEbaySearchResults.value;

export default alertEbaySearchResultsSlice.reducer