import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.jsx";
import Auth from "layouts/Auth.jsx";
import "assets/css/siasapp-dashboard.css";
import store from './store'
import {Provider} from "react-redux";

const hist = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/auth" component={Auth} />
          <Redirect from="/" to="/auth/login-page" />
          <Redirect from='*' to='/auth/login-page' />
        </Switch>
      </Router>
    </Provider>,
  document.getElementById("root")
);
