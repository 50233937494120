import React, {useEffect, useState} from "react";
import axios from '../../helpers/axios-api';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopOutlined from '@material-ui/icons/StopRounded';
import {DeleteOutline} from "@material-ui/icons";
import AddSearch from "../../components/Popups/AddSearch";
import {Badge, Grid, IconButton, Select, SwipeableDrawer, Tooltip} from "@mui/material";
import EditSearch from "../../components/Popups/EditSearch";
import {getOffset, htmlDecode} from "../../helpers/common";
import {createArray, createItemFilterArray} from "../../helpers/ebay-search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import EbaySearchResultsCell from "./components/EbaySearchResultsCell";
import EbaySearchFooter from "./components/EbaySearchFooter";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  buttonDisabled: {
    opacity: '50%',
    "&:hover": {
      opacity: '100%'
    }
  }
};

const resultsStyle = { fontSize: '25px', lineHeight: '1' };

const pageSize = 20;

const EBAY_SEARCH_FILTER = 'EBAY_SEARCH_FILTER';
const EBAY_SEARCH_SORT = 'EBAY_SEARCH_SORT';

// const defaultSort = {name: 'asc'};
const defaultSort = [{ field: 'name', sort: 'asc'}];
const defaultFilter = {name: '', category: '', status: ''};

function EbaySearch(props) {
  const { classes } = props;
  const savedFilter = JSON.parse(localStorage.getItem(EBAY_SEARCH_FILTER));
  const savedSort = JSON.parse(localStorage.getItem(EBAY_SEARCH_SORT));
  const [eBaySearchResult, setEBaySearchResult] = useState([]);
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(savedFilter || defaultFilter);
  const [purchaseItemDisable, setPurchaseItemDisable] = useState(true);
  const [batchAction, setBatchAction] = useState('');
  const [tableRowCount, setTableRowCount] = useState(0);
  const [allActiveResults, setAllActiveResults] = useState(0);
  const [newActiveResults, setNewActiveResults] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(savedSort || defaultSort);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedFilter, setExpandedFilter] = useState(false);

  const handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const filterCount = () => {
    let count = 0;
    Object.keys(filter).map(function(objectKey, index) {
      if (filter[objectKey]) count++;
    });
    return count;
  }

  const eBaySearchTableData = eBaySearchResult.map(eBaySearch => {
    return {
      ...eBaySearch,
      search_date: eBaySearch.search_date ? moment(eBaySearch.search_date).format('DD.MM.YYYY HH:mm:ss') : null,
      new_result_date: eBaySearch.new_result_date ? moment(eBaySearch.new_result_date).format('DD.MM.YYYY HH:mm:ss') : null
    };
  });

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    getSearch();
  }

  const handleFilterFormReset = (e) => {
    e.preventDefault();
    setFilter({name: '', category: '', status: ''});
  }

  const changeSort = (sortData) => {
    setSort(sortData);
    getSearch(sortData);
  }

  const getSortField = field => {
    let result = field;
    if (field === 'name') {
      result = 'name';
    } else if(field === 'keyword') {
      result = 'keyword';
    }

    return result;
  }
  const getUrl = (newSort = null, newPage = null) => {const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;
    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/ebay-searches?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getFilterString = () => {
    let filterString = '';
    const filterParams = [];

    if (filter.name.length > 0) {
      filterParams.push(`filter[name]=${encodeURIComponent(filter.name)}`);
    }

    if (filter.category.toString().length > 0) {
      filterParams.push(`filter[category_id]=${filter.category}`);
    }

    if (filter.status.length > 0) {
      filterParams.push(`filter[status]=${filter.status}`);
    }

    if (filterParams.length > 0) {
      filterString = filterParams.join('&')
    }

    return filterString;
  }

  const handleFilterCollapse = () => setExpandedFilter(!expandedFilter);

  const toggleFilter = expandedFilter =>
      event => {
        if (
            event &&
            (event.type === 'keydown'
                && event.keyCode === 'Tab' ||
                event.keyCode === 'Shift')
        ) {
          return;
        }

        setExpandedFilter(expandedFilter);
      };

  const getSearch = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then(response => {
          setTableRowCount(response.data.total.count);
          setAllActiveResults(response.data.allActiveResults);
          setNewActiveResults(response.data.newActiveResults);
          let newArray = response.data.data.map((item, index) => {
            let filtersArrayAspects = createArray(item.filters.aspects);
            let filtersArrayItemFilter = Object.hasOwn(item.filters, 'itemFilter') ? createItemFilterArray(item.filters.itemFilter) : [];
            let filters = filtersArrayAspects.concat(filtersArrayItemFilter);
            item.name = item.name ? htmlDecode(item.name) : null;
            item.filterHTML = filters.join(' \n');
            item.category = Object.values(item.categories).join(', \n');
            item.results = '\n' + item.views + '\n' + item.search_date;
            item.action = 'buttons';
            setIsLoading(false);
            return item;
          });
          setEBaySearchResult(newArray);
          setIsLoading(false);
        })
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 200, filterable: false, sortable: true, flex: 1},
    { field: 'category', headerName: 'Category', minWidth: 150, filterable: false, sortable: false, flex: 2},
    { field: 'keyword', headerName: 'Keyword', width: 200, filterable: false, flex: 1},
    { field: 'filterDetails',
      headerName: 'Filter Details',
      minWidth: 150,
      sortable: false,
      flex: 2,
      filterable: false,
      renderCell: (params) => {
        return (<span dangerouslySetInnerHTML={{__html: params.row.filterHTML}} />)
      }
    },
    {
      field: 'results',
      headerName: 'Results',
      width: 200,
      align: 'center',
      sortable: false,
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const resultsCount = params.row.number_of_results ? parseInt(params.row.number_of_results, 10) : 0;
        const newResultsCount = params.row.number_of_new_results ? parseInt(params.row.number_of_new_results, 10) : 0;


        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <EbaySearchResultsCell
                  resultsCount={resultsCount}
                  newResultsCount={newResultsCount}
                  searchId={params.row.id}
              />
              <span style={{color: 'grey'}}>{params.row.search_date}</span>
            </div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        const deactivateSearch = () => {
          axios.patch(`/update-ebay-search/update-status/`+params.row.id, {status: 0})
              .then(() => {
                getSearch();
              }).catch(error => {
                console.log(error);
              });
        };
        const activateSearch = () => {
          axios
              .patch(`/update-ebay-search/update-status/`+params.row.id, {status: 1})
              .then(() => {
                getSearch();
              }).catch(error => {
                console.log(error);
              });
        }
        return (
            <div>
              <Tooltip title={"Enable"}>
                <IconButton onClick={activateSearch}>
                  <PlayCircleOutlineIcon className={(params.row.status === true ? classes.buttonDisabled : '')} style={{color: 'rgb(8 245 5)', fontSize: '24px', cursor: 'pointer'}}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Disable"}>
                <IconButton  onClick={deactivateSearch}>
                  <StopOutlined className={(params.row.status === true ? '' : classes.buttonDisabled)} style={{color: 'rgb(249 3 13)', fontSize: '24px', cursor: 'pointer'}}/>
                </IconButton>
              </Tooltip>
            </div>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        const deleteSearch = () => {
          axios.delete(`/ebay-search/`+params.row.id).then(response => {
            getSearch();
          });
        }
        return (
            <div>
              <EditSearch
                  searchId={params.row.id}
                  handleFinishEdit={handleFinishEdit}
              />
              <Tooltip title={"Delete"}>
                <IconButton onClick={deleteSearch}>
                  <DeleteOutline  style={{color: 'rgb(249 3 13)', fontSize: '24px'}}/>
                </IconButton>
              </Tooltip>
            </div>
        )
      }
    },
  ];

  const onTableItemSelection = (selectedItem) => {
    if(selectedItem.length !== 0) {
      setSelectedTableItem(selectedItem);      
      setPurchaseItemDisable(false);
    } else {
      setPurchaseItemDisable(true);
      setSelectedTableItem(selectedItem);
    }
  }

  useEffect(() => {
    getSearch();
  }, [page, sort]);

  useEffect(() => {
    localStorage.setItem(EBAY_SEARCH_SORT, JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(EBAY_SEARCH_FILTER, JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/ebay-search-categories`)
        .then((response) => {
          setCategories(response.data.data);
          getSearch();
        })
        .catch(() => console.log('Get categories error'))
  }, []);

  const handleBatchFormSubmit = (e) => {
    e.preventDefault();
    let url = null;
    let request = {};
    switch(batchAction) {
      case 'enable':
        url = `/ebay-searches/update-status/batch`;
        request = {ids: selectedTableItem, status: 1};
        break;
      case 'disable':
        url = `/ebay-searches/update-status/batch`;
        request = {ids: selectedTableItem, status: 0};
        break;
      case 'remove':
        url = `/ebay-searches/remove/batch`;
        request = {ids: selectedTableItem};
        break;
    }

    if (url) {
      axios.patch(url, request)
          .then(() => {
            getSearch();
          }).catch(error => {
        console.log('Batch action error');
      });
    }
  }

  const handleFinishEdit = () => {
    getSearch();
  }

  const handleFinishAdd = () => {
    getSearch();
  }

  const handleBatchFieldChange = (e) => {
    setBatchAction(e.target.value);
  }

  const iconFilter = expandedFilter
      ?
      <Badge badgeContent={filterCount()} color="success"><FilterAltOffIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>
      : <Badge badgeContent={filterCount()} color="success"><FilterAltIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>;

  const categoriesOptions = categories.map((category, index) =>
        <MenuItem key={index} value={category.id}>
          {category.name}
        </MenuItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>eBay Search</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "20px" }}>
            <Stack direction={'row'} alignItems="center" style={{marginBottom: '15px'}}>
              <div style={{marginRight: "10px"}}>
                <div>
                  <a onClick={handleFilterCollapse}>
                    <span style={{display: "flex", alignItems: "center"}}>
                      {iconFilter}<DoubleArrowIcon style={{marginLeft: "-7px"}} />
                    </span>
                  </a>
                </div>
                <SwipeableDrawer
                    anchor={'left'}
                    open={expandedFilter}
                    onClose={toggleFilter(false)}
                    onOpen={toggleFilter(true)}
                >
                  <div style={{padding: "15px", maxWidth: "300px"}}>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                      <IconButton onClick={() => setExpandedFilter(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <h3 style={{marginTop: "10px"}}>Filter</h3>
                    <form
                        onSubmit={handleFilterFormSubmit}
                        onReset={handleFilterFormReset}
                    >
                      <Grid
                          container
                          alignItems="center"
                          justify="flex-start"
                          style={{ marginBottom: "30px" }}
                          spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                              id="name-input"
                              name="name"
                              type="text"
                              label={"Name"}
                              placeholder={'Name'}
                              value={filter.name}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl style={{width: "100%"}}>
                            <InputLabel style={{backgroundColor: "white"}}>Status</InputLabel>
                            <Select
                                displayEmpty
                                name="status"
                                value={filter.status}
                                onChange={handleFilterFieldChange}
                                style={{width: "100%"}}
                            >
                              <MenuItem key="none" value="">None</MenuItem>
                              <MenuItem key="enabled" value="1">Enabled</MenuItem>
                              <MenuItem key="disabled" value="0">Disabled</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl style={{width: "100%"}}>
                            <InputLabel style={{backgroundColor: "white"}}>Category</InputLabel>
                            <Select
                                displayEmpty
                                name="category"
                                value={filter.category}
                                onChange={handleFilterFieldChange}
                                style={{width: "100%"}}
                            >
                              <MenuItem key="none" value="">None</MenuItem>
                              {categoriesOptions}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight: "15px"}}
                            >
                              Apply
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="reset"
                            >
                              Reset
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </SwipeableDrawer>
              </div>
              <div style={{ width: "100%" }}>
                <form
                    onSubmit={handleBatchFormSubmit}
                >
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                      style={{ width: "100%" }}
                      spacing={2}
                  >
                    <Grid item xs={3}>
                      <FormControl style={{width: "100%"}}>
                        <InputLabel style={{backgroundColor: "white"}}>Batch Action</InputLabel>
                        <Select
                            displayEmpty
                            name="batchAction"
                            value={batchAction}
                            onChange={handleBatchFieldChange}
                            style={{width: "100%"}}
                        >
                          <MenuItem key="enable" value="enable">
                            Enable
                          </MenuItem>
                          <MenuItem key="disable" value="disable">
                            Disable
                          </MenuItem>
                          <MenuItem key="remove" value="remove">
                            Remove
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div style={{minWidth: "114px"}}>
                <AddSearch handleFinishAdd={handleFinishAdd} />
              </div>
            </Stack>
            <PureTableStyled
              checkboxSelection={true}
              tableHead={columns}
              tableData={eBaySearchTableData}
              onSelectionModelChange = {(selectedItem) => {onTableItemSelection(selectedItem)}}
              rowCount = {Number(tableRowCount)}
              onPageChanged = {getSearch}
              pageSize = {pageSize}
              page = {page}
              setPage = {setPage}
              onSortModelChange = {changeSort}
              isLoading = {isLoading}
              sortModel={sort}
              customBackgroundRow={true}
              components={{Footer: EbaySearchFooter}}
              componentsProps={{
                footer: { total: allActiveResults, new: newActiveResults }
              }}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(EbaySearch);
