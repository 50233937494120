import React, {useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// core components
import headerStyle from "assets/jss/siasapp-dashboard/components/headerStyle.jsx";
import axios from '../../helpers/axios-api';
import IconButton from "@material-ui/core/IconButton";
import SnackbarMessage from "../Common/SnackbarMessage";

function Logout({ ...props }) {
  const { classes } = props;
  const [messageShow, setMessageShow] = useState(false);
  const [message, setMessage] = useState('Something went wrong!');
  const [severity, setSeverity] = useState('error');

  const handleLogout = (e) => {
    e.preventDefault();
    axios.post(`/logout`).then(response => {
      document.location.href = "/auth/login-page";
    }).catch(err =>{
      setMessage('Something went wrong. Unable to logout');
      setSeverity('error');
      setMessageShow(true);
    });
  }

  return (<>
        <IconButton color="inherit" onClick={handleLogout}>
          <ExitToAppIcon style={{fontSize: "30px"}} />
        </IconButton>
        <SnackbarMessage
            open={messageShow}
            autoHideDuration={6000}
            setOpen={setMessageShow}
            severity={'error'}
            message={message}
        />
      </>
  );
}

Logout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(headerStyle)(Logout);
