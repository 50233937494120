import { configureStore } from '@reduxjs/toolkit'
import isUpdateSearchResultsReducer from "./features/counter/isUpdateSearchResultsSlice";
import alertEbaySearchResultsReducer from "./features/ebaySearchResults/alertEbaySearchResultsSlice";
import refreshEbaySearchResultsReducer from "./features/ebaySearchResults/refreshEbaySearchResultsSlice";

export default configureStore({
    reducer: {
        isUpdateSearchResults: isUpdateSearchResultsReducer,
        alertEbaySearchResults: alertEbaySearchResultsReducer,
        refreshEbaySearchResults: refreshEbaySearchResultsReducer
    },
})