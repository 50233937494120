import React, {useState} from "react";
import PropTypes from "prop-types";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from '../../../helpers/axios-api';
import fileDownload from 'js-file-download';

const AmazonOrdersExport = props => {
    const { exportUrl } = props;
    const [isLoading, setIsLoading] = useState(false);

    const getAmazonOrdersExcel = () => {
        setIsLoading(true);
        axios({
            url: exportUrl,
            method: 'GET',
            responseType: 'blob'
        })
            .then(response => {
                setIsLoading(false);
                fileDownload(response.data, `AmazonOrders.xlsx`);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<FileDownloadIcon />}
            variant={"outlined"}
            onClick={getAmazonOrdersExcel}
        >
            Export
        </LoadingButton>
    );
}

AmazonOrdersExport.propTypes = {
    exportUrl: PropTypes.string
};

export default AmazonOrdersExport;