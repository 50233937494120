import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export default function Username(props) {
    return (
        <div>
            <AccountCircleIcon></AccountCircleIcon><br/>
            <span>admin admin</span>
        </div>
    );
}