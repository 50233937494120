import React, {useEffect, useState} from "react";

import axios from '../../helpers/axios-api';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Badge, Grid, IconButton, SwipeableDrawer, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {getOffset} from "../../helpers/common";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import WarehouseItemComments from "./components/WarehouseItemComments";
import WarehouseItemNote from "./components/WarehouseItemNote";
import WarehouseItemPublish from "./components/WarehouseItemPublish";
import GridViewIcon from "@mui/icons-material/GridView";
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import NotesPopup from "../../components/Popups/NotesPopup";
import WarehouseFooter from "./components/WarehouseFooter";
import WarehouseListOrderCell from "./components/WarehouseListOrderCell";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const pageSize = 20;

const defaultSort = [{ field: 'date', sort: 'desc'}];
const defaultFilter = {orderId: '', partNumber: '', priceFrom: '', priceTo: '', asin: ''};

const WAREHOUSE_LIST_SORT = 'WAREHOUSE_LIST_SORT';
const WAREHOUSE_LIST_SEARCH = 'WAREHOUSE_LIST_SEARCH';
const WAREHOUSE_LIST_FILTER = 'WAREHOUSE_LIST_FILTER';

function WarehouseList(props) {
  const { classes } = props;
  const savedSort = JSON.parse(localStorage.getItem(WAREHOUSE_LIST_SORT));
  const savedSearch = JSON.parse(localStorage.getItem(WAREHOUSE_LIST_SEARCH));
  const savedFilter = JSON.parse(localStorage.getItem(WAREHOUSE_LIST_FILTER));
  const [warehouseStocks, setWarehouseStocks] = useState({data: [], total: 0, totalPrice: 0});
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [filter, setFilter] = useState(savedFilter || defaultFilter);
  const [purchaseItemDisable, setPurchaseItemDisable] = useState(true);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(savedSort || defaultSort);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [search, setSearch] = useState(savedSearch || '');

  const handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const filterCount = () => {
    let count = 0;
    Object.keys(filter).map(function(objectKey, index) {
      if (filter[objectKey]) count++;
    });
    return count;
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }

  const warehouseStocksTableData = warehouseStocks.data.map((item, index) => {
    return {
      id: index,
      key: index,
      itemId: item.id,
      date: item.purchase_date ? moment(item.purchase_date).format('DD.MM.YYYY') : null,
      order: {
        id: item.ebay_order_id,
      },
      partNumber: item.part_number,
      quantity: item.qty,
      price: item.price,
      amazon: {
        asin: item.amazon_asin
      },
      title: item.title,
      picture: item.item_picture_url && item.item_picture_url.length > 0 ? item.item_picture_url[0] : null,
      comments: item.comments
    }
  });

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    getItems(defaultSort, 0);
  }

  const handleFilterFormReset = (e) => {
    e.preventDefault();
    resetFilter();
  }

  const resetFilter = () => {
    setFilter({orderId: '', partNumber: '', priceFrom: '', priceTo: '', asin: ''});
  }

  const changeSort = (sortData) => {
    setSort(sortData);
    getItems(sortData);
  }

  const handleNoteAdded = () => {
    getItems();
  }

  const handleItemPublished = () => {
    getItems();
  }

  const getSortField = field => {
    let result = field;
    if (field === 'date'){
      result = 'created_at';
    }

    return result;
  }

  const getUrl = (newSort = null, newPage = null) => {

    const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;

    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/get-warehouse-stocks-listed?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getFilterString = () => {
    let filterString = '';
    const filterParams = [];

    if (search.length > 0) {
      return `search=${encodeURIComponent(search)}`;
    }

    if (filter.orderId.length > 0) {
      filterParams.push(`filter[order_id]=${filter.orderId}`);
    }

    if (filter.partNumber.length > 0) {
      filterParams.push(`filter[part_number]=${filter.partNumber}`);
    }

    if (filter.asin.length > 0) {
      filterParams.push(`filter[amazon_asin]=${filter.asin}`);
    }

    if (filter.priceFrom.toString().length > 0) {
      filterParams.push(`filter[price_from]=${filter.priceFrom}`);
    }

    if (filter.priceTo.toString().length > 0) {
      filterParams.push(`filter[price_to]=${filter.priceTo}`);
    }

    if (filterParams.length > 0) {
      filterString = filterParams.join('&')
    }

    return filterString;
  }

  const handleFilterCollapse = () => setExpandedFilter(!expandedFilter);

  const toggleFilter = expandedFilter =>
      event => {
        if (
          event &&
            (event.type === 'keydown'
            && event.keyCode === 'Tab' ||
            event.keyCode === 'Shift')
        ) {
          return;
        }

        setExpandedFilter(expandedFilter);
      };

  const getItems = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then((response) => {
          setWarehouseStocks(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          console.log('Get warehouse stocks error');
        });
  }

  const columns = [
    { field: 'date', headerName: 'Date', width: 100, filterable: false, flex: 1},
    { field: 'order', headerName: 'Order ID', width: 150, filterable: false, sortable: false, flex: 1,
      renderCell: params => <WarehouseListOrderCell orderId={params.row.order.id} />
    },
    { field: 'partNumber', headerName: 'Part Number', width: 125, filterable: false, sortable: false, flex: 1},
    { field: 'quantity', headerName: 'Quantity', width: 150, filterable: false, sortable: false, flex: 1,
      renderCell: params => <span style={{fontSize: '16px', fontWeight: 700}}>{params.row.quantity}</span>},
    { field: 'price', headerName: 'Price', width: 150, sortable: false, flex: 1,
      renderCell: params => <span style={{fontSize: '16px', fontWeight: 700}}>{`$${params.row.price}`}</span>},
    { field: 'comments', headerName: 'Notes', width: 300, sortable: false, flex: 1,
      renderCell: params => {
        return <NotesPopup
            orderId={params.row.itemId}
            comments={params.row.comments}
            url={'add-warehouse-stock-comment'}
            type={'warehouse_stock_id'}
            handleNoteAdded={handleNoteAdded}
        />;
      }
    },
    { field: 'amazon', headerName: 'Amazon', width: 200, flex: 1,
      renderCell: (params) => {
        if (params.row.amazon.asin) {
          return <a href={`https://www.amazon.com/gp/product/${params.row.amazon.asin}`} target={'_blank'}>{params.row.amazon.asin}</a>;
        } else {
          return <WarehouseItemPublish
              itemId={params.row.id}
              handleItemPublished={handleItemPublished}
              item={{
                id: params.row.itemId,
                picture: params.row.picture,
                title: params.row.title,
                partNumber: params.row.partNumber,
                price: params.row.price
              }}
            />;
        }
      }
    }
  ];

  const onTableItemSelection = (selectedItem) => {
    if(selectedItem.length !== 0) {
      setSelectedTableItem(selectedItem);      
      setPurchaseItemDisable(false);
    } else {
      setPurchaseItemDisable(true);
      setSelectedTableItem(selectedItem);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/get-warehouse-stocks-listed`)
        .then((response) => {
          setWarehouseStocks(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          console.log('Get warehouse stocks error');
        })
  }, []);

  useEffect(() => {
    localStorage.setItem(WAREHOUSE_LIST_FILTER, JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    localStorage.setItem(WAREHOUSE_LIST_SORT, JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(WAREHOUSE_LIST_SEARCH, JSON.stringify(search));
  }, [search]);

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    resetFilter();
    getItems(defaultSort, 0);
  }

  const onPageChanged = page => {
    setPage(page);
    getItems(null, page);
  }

  const toggleChange = (event, newAlignment) => {
    if (newAlignment === 'group'){
      document.location.href = '/admin/warehouse-stock-group';
    }
  };

  const iconFilter = expandedFilter
      ? <Badge badgeContent={filterCount()} color="success"><FilterAltOffIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>
      : <Badge badgeContent={filterCount()} color="success"><FilterAltIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Warehouse Stock</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "17px" }}>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" style={{ marginBottom: "15px" }}>
              <div>
                <div>
                  <a onClick={handleFilterCollapse}>
                <span style={{display: "flex", alignItems: "center"}}>
                  {iconFilter}<DoubleArrowIcon style={{marginLeft: "-7px"}} />
                </span>
                  </a>
                </div>
                <SwipeableDrawer
                    anchor={'left'}
                    open={expandedFilter}
                    onClose={toggleFilter(false)}
                    onOpen={toggleFilter(true)}
                >
                  <div style={{padding: "15px", maxWidth: "300px"}}>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                      <IconButton onClick={() => setExpandedFilter(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <h3 style={{marginTop: "10px"}}>Filter</h3>
                    <form
                        onSubmit={handleFilterFormSubmit}
                        onReset={handleFilterFormReset}
                    >
                      <Grid
                          container
                          alignItems="center"
                          justify="flex-start"
                          spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                              id="order-id-input"
                              name="orderId"
                              type="text"
                              label={"Order Id"}
                              placeholder={'Order ID'}
                              value={filter.orderId}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="part-number-input"
                              name="partNumber"
                              type="text"
                              label={"Part Number"}
                              placeholder={'Part Number'}
                              value={filter.partNumber}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="amazon-asin"
                              name="asin"
                              type="text"
                              label={"ASIN"}
                              placeholder={'SIN'}
                              value={filter.asin}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="price-from-input"
                              name="priceFrom"
                              type="number"
                              label={"Price from"}
                              placeholder={'Price from'}
                              value={filter.priceFrom}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              id="price-to-input"
                              name="priceTo"
                              type="number"
                              label={"Price to"}
                              placeholder={'Price to'}
                              value={filter.priceTo}
                              style={{width: "100%"}}
                              onChange={handleFilterFieldChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight: "15px"}}
                            >
                              Apply
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="reset"
                            >
                              Reset
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </SwipeableDrawer>
              </div>
              <div style={{width: '75%'}}>
                <form onSubmit={handleSearchFormSubmit}>
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                      style={{ width: "100%", justifyContent: 'center'}}
                      spacing={2}
                  >
                    <Grid item xs={6}>
                      <FormControl style={{width: "100%"}}>
                        <TextField
                            id="search-input"
                            name="search"
                            type="text"
                            label={"Search"}
                            placeholder={'Search'}
                            value={search}
                            style={{width: "100%"}}
                            onChange={handleSearchChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div>
                <ToggleButtonGroup
                    color="primary"
                    value='list'
                    exclusive
                    onChange={toggleChange}
                >
                  <ToggleButton value="group">
                    <div style={{fontSize:'10px'}}>Group</div>
                  </ToggleButton>
                  <ToggleButton value="list">
                    <div style={{fontSize:'10px'}}>List</div>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Stack>
            <PureTableStyled
              tableHead={columns}
              tableData={warehouseStocksTableData}
              onSelectionModelChange = {(selectedItem) => {onTableItemSelection(selectedItem)}}
              selectionModel = {selectedTableItem}
              rowCount = {warehouseStocks.total}
              pageSize = {pageSize}
              page = {page}
              setPage = {onPageChanged}
              onSortModelChange = {changeSort}
              isLoading = {isLoading}
              checkboxSelection = {false}
              sortModel={sort}
              components={{Footer: WarehouseFooter}}
              componentsProps={{
                footer: {
                  totalPrice: warehouseStocks.totalPrice,
                }
              }}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(WarehouseList);
