import React, {useEffect, useState} from "react";
import axios from '../../helpers/axios-api';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Grid, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {getOffset} from "../../helpers/common";
import InventoryPopup from "../../components/Popups/InventoryPopup";
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import WarehouseFooter from "./components/WarehouseFooter";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const pageSize = 20;

const defaultSort = [{ field: 'partNumber', sort: 'asc'}];

const WAREHOUSE_GROUP_SORT = 'WAREHOUSE_GROUP_SORT';
const WAREHOUSE_GROUP_SEARCH = 'WAREHOUSE_GROUP_SEARCH';

function WarehouseGroup(props) {
  const { classes } = props;
  const savedSort = JSON.parse(localStorage.getItem(WAREHOUSE_GROUP_SORT));
  const savedSearch = JSON.parse(localStorage.getItem(WAREHOUSE_GROUP_SEARCH));
  const [warehouseStocks, setWarehouseStocks] = useState({data: [], total: 0, totalPrice: 0});
  const [filter, setFilter] = useState({product: '', partNumber: ''});
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(savedSort || defaultSort);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState(savedSearch || '');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    localStorage.setItem(WAREHOUSE_GROUP_SORT, JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(WAREHOUSE_GROUP_SEARCH, JSON.stringify(search));
  }, [search]);

  const warehouseStocksTableData = warehouseStocks.data.map((item, index) => {
    return {
      id: index,
      key: index,
      product: {
        picture: item.picture && item.picture.length > 0 ? item.picture[0] : null,
        title: item.title,
        link: item.item_url,
      },
      partNumber: item.part_number,
      quantity: item.group_qty,
      quantityPublished: item.group_qty_published ? item.group_qty_published : 0,
      price: item.group_cost,
    }
  });

  const resetFilter = () => {
    setFilter({product: '', partNumber: ''});
  }

  const changeSort = (sortData) => {
    setSort(sortData);
    getItems(sortData);
  }

  const getSortField = field => {
    let result = field;
    if (field === 'partNumber'){
      result = 'part_number';
    }

    return result;
  }

  const getUrl = (newSort = null, newPage = null) => {

    const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;

    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/get-warehouse-stocks-grouped?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString;

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getFilterString = () => {
    let filterString = '';
    const filterParams = [];

    if (search.length > 0) {
      return `search=${encodeURIComponent(search)}`;
    }

    if (filter.product.length > 0) {
      filterParams.push(`filter[product]=${filter.product}`);
    }

    if (filter.partNumber.length > 0) {
      filterParams.push(`filter[part_number]=${filter.partNumber}`);
    }

    if (filterParams.length > 0) {
      filterString = filterParams.join('&')
    }

    return filterString;
  }

  const getItems = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then(response => {
          setWarehouseStocks({...response.data});
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          console.log('Get warehouse stocks error');
          setIsLoading(false);
        })
  }

  const columns = [
    { field: 'product', headerName: 'Product', minWidth: 400, filterable: false, sortable: false, flex: 1,
      renderCell: (params) => {
        const itemLink = params.row.product.title
            ? <a href={params.row.product.link} target={'_blank'}>{params.row.product.title}</a>
            : <></>;
        return (
            <div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: '75px', display: 'inline-block', marginRight: '10px'}}><img src={params.row.product.picture ? params.row.product.picture : ''} width={'75px'}/></div>
                <div style={{display: 'inline-block', width: '300px', whiteSpace: 'pre-wrap', paddingRight: '10px'}}>{itemLink}</div>
              </div>
            </div>
        );
      }
    },
    { field: 'partNumber', headerName: 'Part Number', width: 200, filterable: false, flex: 1},
    { field: 'quantity', headerName: 'Quantity', width: 150, filterable: false, sortable: false, flex: 1,
      renderCell: params => <span style={{fontSize: '18px', fontWeight: 700}}>
        {params.row.quantity} / <span style={{color: "green"}}>{params.row.quantityPublished}</span>
      </span>
    },
    { field: 'price', headerName: 'Price', width: 150, sortable: false, flex: 1,
      renderCell: params => <span style={{fontSize: '18px', fontWeight: 700}}>{`$${params.row.price}`}</span>},
    { field: 'actions', headerName: 'Action', width: 100, filterable: false, sortable: false, flex: 1,
      renderCell: params => {
        let action = null;
        if (params.row.partNumber) {
          action = <InventoryPopup
              partNumber={params.row.partNumber}
              product={params.row.product}
          />
        }

        return action;
      }
    }
  ];

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    resetFilter();
    getItems(defaultSort, 0);
  }

  const onPageChanged = page => {
    setPage(page);
    getItems(null, page);
  }
  const toggleChange = (event, newAlignment) => {
    if (newAlignment === 'list'){
      document.location.href = '/admin/warehouse-stock-list';
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Warehouse Stock</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "17px" }}>
            <Grid container direction={'row'} alignItems="center" style={{ marginBottom: "15px" }}>
              <Grid item xs={11}>
                <div style={{width: '100%'}} >
                <form onSubmit={handleSearchFormSubmit}>
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                      style={{ width: "100%", justifyContent: 'center'}}
                      spacing={2}
                  >
                    <Grid item xs={6}>
                      <FormControl style={{width: "100%"}}>
                        <TextField
                            id="search-input"
                            name="search"
                            type="text"
                            label={"Search"}
                            placeholder={'Search'}
                            value={search}
                            style={{width: "100%"}}
                            onChange={handleSearchChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              </Grid>
              <Grid item xs={1}>
                <ToggleButtonGroup
                    color="primary"
                    value='group'
                    exclusive
                    onChange={toggleChange}
                >
                  <ToggleButton value="group">
                      <div style={{fontSize:'10px'}}>Group</div>
                  </ToggleButton>
                  <ToggleButton value="list">
                      <div style={{fontSize:'10px'}}>List</div>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <PureTableStyled
              tableHead={columns}
              tableData={warehouseStocksTableData}
              rowCount = {warehouseStocks.total}
              pageSize = {pageSize}
              page = {page}
              setPage = {onPageChanged}
              onSortModelChange = {changeSort}
              isLoading = {isLoading}
              checkboxSelection = {false}
              sortModel={sort}
              components={{Footer: WarehouseFooter}}
              componentsProps={{
                footer: {
                  totalPrice: warehouseStocks.totalPrice,
                }
              }}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(WarehouseGroup);
