import React, {useState, useEffect} from "react";
// @material-ui/core components
import ReactHowler from 'react-howler';
import {useDispatch, useSelector} from "react-redux";
import {
  selectAlertEbaySearchResults,
  setAlertEbaySearchResults
} from "../../features/ebaySearchResults/alertEbaySearchResultsSlice";

const SoundEventHowler = props => {
  const { src } = props;

  const isAlert = useSelector(selectAlertEbaySearchResults);
  const dispatch = useDispatch();
  const [play, setPlay] = useState(false);

  useEffect(() => {
    if (isAlert) {
      setPlay(true);
    }
  }, [isAlert]);

  const handleSoundFinished = () => {
    setPlay(false);
    dispatch(setAlertEbaySearchResults(false));
  }

  return <ReactHowler
      src={src}
      playing={play}
      onEnd={handleSoundFinished}
  />;
}

export default SoundEventHowler;
