import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import axios from '../../../helpers/axios-api';
import {
    IconButton, Tooltip,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import withStyles from "@material-ui/core/styles/withStyles";
import SnackbarMessage from "../../../components/Common/SnackbarMessage";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";

const styles = {
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '15px',
        width: '100%',
    },
    iconClass: {
        fontSize: '24px',
    },
    iconWrapperClass: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
    },
    iconInnerClass: {
        color: '#ff0000'
    }
}

function DeleteSetting(props) {
    const {settingId, handleFinish, classes} = props;
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [messageShow, setMessageShow] = useState(false);
    const [message, setMessage] = useState('Something went wrong!');
    const [severity, setSeverity] = useState('error');

    const openConfirmDialog = () => {
        setConfirmDeleteOpen(true);
    }

    const deleteSetting = () => {
        const url = `/delete-setting/${settingId}`
        axios.delete(url).then((response) => {
            if (response.data.ok) {
                setMessage(response.data.message);
                setSeverity('success');
                setMessageShow(true);
                setConfirmDeleteOpen(false);
                handleFinish();
            } else {
                setMessage(response.data.message);
                setSeverity('error');
                setMessageShow(true);
            }
        }).catch((err) => {
            console.log(err);
            setMessage('Something went wrong!');
            setSeverity('error');
            setMessageShow(true);
            setConfirmDeleteOpen(false);
        });
    }

    return (
        <Stack spacing={1} direction="row">
            <Tooltip title={'Delete'}>
                <div>
                    <IconButton onClick={openConfirmDialog} className={classes.iconClass} >
                        <div className={classes.iconWrapperClass}>
                            <CloseIcon className={classes.iconInnerClass}/>
                        </div>
                    </IconButton>
                </div>
            </Tooltip>
            <ConfirmDialog
                title={"Delete setting?"}
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                onConfirm={deleteSetting}
            >
                Are you sure you want to delete setting?
            </ConfirmDialog>
            <SnackbarMessage
                open={messageShow}
                autoHideDuration={6000}
                setOpen={setMessageShow}
                severity={severity}
                message={message}
            />
        </Stack>
    );
}

export default withStyles(styles)(DeleteSetting);