import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "assets/jss/siasapp-dashboard/components/sidebarStyle.jsx";
import Stack from "@mui/material/Stack";
import {Divider} from "@mui/material";
import Logout from "../Logout/Logout";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Username from "../User/Username";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center"
}));

const Navigation = ({ ...props }) => {
    function activeRoute(routeName) {
        return props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    const { classes, color, logo, image, logoText, routes } = props;
    let links = (
        <Stack direction={{ xs: 'column', sm: 'row' }}
               spacing={{ xs: 1, sm: 2, md: 4 }}>
            {routes.map((prop, key) => {
                if (prop.layout === "/admin" && prop.isEnabled) {
                    var activePro = " ";
                    let isActive = activeRoute(prop.layout + prop.path);
                    const name = prop.label ? prop.label : prop.name;
                    const purpleFontClasses = classNames({
                        [" " + classes.purpleFont]: activeRoute(prop.layout + prop.path)
                    });
                    return (
                        <NavLink
                            to={prop.layout + prop.path}
                            className={activePro + classes.itemNavLink}
                            activeClassName="active"
                            key={key}
                        >
                            <ListItem
                                button
                                className={classes.itemLink}
                            >
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.listItemIcon, purpleFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.listItemIcon, purpleFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                                <ListItemText
                                    primary={props.rtlActive ? prop.rtlName : name}
                                    className={classNames(classes.listItemText, purpleFontClasses, {
                                        [classes.itemTextRTL]: props.rtlActive
                                    })}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                }
            })}
        </Stack>
    );
    return (
        <Box sx={{padding: "15px 30px", display: 'inline-flex', width: "100%", justifyContent:"space-between"}}>
            <Grid container>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}>
                        <Item>
                            <img src={logo} width={"116px"} height={"41px"}/>
                        </Item>
                        <Item>
                            {links}
                        </Item>
                        <Item>
                            <Username />
                        </Item>
                        <Item>
                            <Logout />
                        </Item>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

Navigation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Navigation);
