import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {GridFooterContainer, GridPagination} from "@mui/x-data-grid";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    totalMain: {
        color: '#9c27b0',
        fontWeight: "bold",
    },
    totalSecondary: {
        color: 'green',
        fontWeight: "bold",
    },
};

const EbaySearchFooter = props => {
    const { classes } = props;
    return (
        <GridFooterContainer>
            <Box sx={{ padding: "10px", display: "flex" }}>
                <span>Total Search Results:&nbsp;</span>
                <span className={classes.totalMain}>{props.total}</span>&nbsp;/&nbsp;
                <span className={classes.totalSecondary}>{props.new}</span>
            </Box>
            <GridPagination />
        </GridFooterContainer>
    );
}

EbaySearchFooter.propTypes = {
    total: PropTypes.number,
    new: PropTypes.number
};

export default withStyles(styles)(EbaySearchFooter);