import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from "@mui/material/TextField";

export default function DatePicker(props) {

    const { name, label, value, onChange, format, allowHelperText, error = null, inputParams, ...other } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                label={label}
                inputFormat={format}
                name={name}
                value={value}
                {...(error && { error: true, helperText: allowHelperText && error })}
                onChange={date =>onChange(convertToDefEventPara(name,date))}
                {...other}
                renderInput={(params) => <TextField {...inputParams} {...params} />}
            />
        </LocalizationProvider>


        // <LocalizationProvider>
        //     <DatePicker
        //         disableToolbar
        //         variant="inline"
        //         inputVariant="outlined"
        //         label={label}
        //         format={format}
        //         name={name}
        //         value={value}
        //         {...(error && { error: true, helperText: allowHelperText && error })}
        //         onChange={date =>onChange(convertToDefEventPara(name,date))}
        //         {...other}
        //     />
        // </LocalizationProvider>
    )
}
