import React from "react";
// @material-ui/core components
import { DataGrid } from '@mui/x-data-grid';
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import tableStyle from "assets/jss/siasapp-dashboard/components/tableStyle.jsx";
function CustomTable({ ...props }) {
  const { classes, tableHead, tableData, tableHeaderColor, onSelectionModelChange, selectionModel } = props;
  return (
    <div style={{ height: 600, width: '100%' }}>
        <DataGrid
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        rows={tableData}
        columns={tableHead}
        pageSize={10}
        rowsPerPageOptions={[2]}
        checkboxSelection
      />
    </div>
  );
}
export default withStyles(tableStyle)(CustomTable);
