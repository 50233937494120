import React, {useState, useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Alert, Dialog, DialogContent, Tooltip} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Input from "@material-ui/core/Input";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import ClearIcon from '@mui/icons-material/Clear';
import axios from '../../helpers/axios-api';
import SnackbarMessage from "../Common/SnackbarMessage";

const productStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    borderBottom: '1px black dashed',
    paddingBottom: '15px'
}

const CustomTableCell = ({ row, name, onChange, index }) => {
    const error = row.errors ? row.errors[name] : false;
    return (
        <TableCell align="left">
            <Input
                error={error}
                value={row[name]}
                name={name}
                onChange={e => onChange(e, row, index)}
            />
        </TableCell>
    );
};

export default function ReceiptPopup(props) {

    const { orderId, ebayOrderId, partNumber, quantity, price } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('error');
    const [rows, setRows] = useState([]);
    const [viewedAlert, setViewedAlert] = useState(false);

    useEffect(() => {
        const initialRows = [];
        for (let i = 0; i < quantity; i += 1) {
            initialRows.push({
                id: 1,
                orderId: orderId,
                ebayOrderId: ebayOrderId,
                partNumber: partNumber,
                quantity: 1,
                price: price,
                errors: {partNumber: false, quantity: false, price: false},
            });
        }
        setRows(initialRows);
    }, []);

    const openPopup = () => {
        setIsOpen(true);
    }

    const closePopup = () => {
        setIsOpen(false);
    }

    const createData = function (partNumber, quantity, price) {
        let orderId = props.orderId;
        return {
            orderId,
            ebayOrderId,
            partNumber,
            quantity,
            price,
            errors: {partNumber: false, quantity: false, price: false}
        }
    };

    const onChange = (e, row, changeIndex) => {
        const value = e.target.value;
        const name = e.target.name;
        const newRows = rows.map((row, index) => {
            if (changeIndex === index) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setRows(newRows);
    }

    const addRow = () => {
        let newRow = createData(partNumber, '1', price);
        let newRows = [...rows];
        newRows.push(newRow);
        setRows(newRows);
    }

    const removeRow = (index) => () => {
        let newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    }

    const validateForm = () => {
        const checkedRows = rows.map(row => {
            let errors = row.errors;
            const partNumber = row.partNumber ? row.partNumber.toString().trim() : null;
            const price = row.price ? parseFloat(row.price.toString().trim()) : null;
            const quantity = row.quantity ? parseInt(row.quantity.toString().trim()) : null;
            if (!partNumber) {
                errors = {...errors, partNumber: true}
            } else {
                errors = {...errors, partNumber: false}
            }

            if (!price) {
                errors = {...errors, price: true}
            } else {
                errors = {...errors, price: false}
            }

            if (!quantity) {
                errors = {...errors, quantity: true}
            } else {
                errors = {...errors, quantity: false}
            }

            return {...row, errors}
        });

        const hasEmptyFields = checkedRows.some(row => {
            const { errors } = row;
            return Object.keys(errors).some(fieldName => errors[fieldName] === true);
        });

        if (hasEmptyFields) {
            setSeverity('error');
            setMessage('Please fill out all fields');
            setViewedAlert(true);
        } else {
            setSeverity('');
            setMessage('');
            setViewedAlert(false);
        }

        setRows(checkedRows);

        return !hasEmptyFields;
    }

    const createWarehouseStock = (e) => {
        e.preventDefault();

        const validateResult = validateForm();
        if (!validateResult) {
            return;
        }

        const request = rows.map(row => {
            return {
                partNumber: row.partNumber,
                price: row.price,
                quantity: row.quantity,
            }
        });

        axios.post(`/create-warehouse-stock`,
            {orderId: orderId, warehouseStocks: request}
        ).then((response) => {
            const severity = response.data.ok ? 'success' : 'error';
            const message = response.data.message ? response.data.message : '';
            setSeverity(severity);
            setMessage(message);
            setViewedAlert(true);
        }).catch(err => {
            console.log(err);
            setSeverity('error');
            setMessage('Create warehouse stocks error');
            setViewedAlert(true);
        });
    }

    return (
        <div>
            <Tooltip title={'Receipt'}>
                <div>
                    <IconButton onClick={openPopup} sx={{color: "rgb(25 118 210)", fontSize: '24px', display: 'flex', flexDirection: 'column'}}>
                        <AllInboxRoundedIcon sx={{color: "rgb(25 118 210)"}}></AllInboxRoundedIcon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog open={isOpen} fullWidth maxWidth="md">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Receipt
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={productStyle}>
                        <img src={props.image} style={{maxWidth: '100px', height: 'auto', marginRight: '20px'}} />
                        <span style={{fontSize: '16px', fontWeight: 700}}>{props.name}</span>
                    </div>
                    <SnackbarMessage
                        open={viewedAlert}
                        autoHideDuration={6000}
                        setOpen={setViewedAlert}
                        severity={severity}
                        message={message}
                    />
                    <TableContainer component={Paper} style={{marginBottom: '15px'}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order ID</TableCell>
                                    <TableCell align="center">Part Number</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Price</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => {
                                    const partNumberError = row.errors ? row.errors['partNumber'] : false;
                                    return <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.ebayOrderId}</TableCell>
                                        <TableCell align="left">
                                            <Input
                                                error={partNumberError}
                                                value={row.partNumber}
                                                name={'partNumber'}
                                                onChange={e => onChange(e, row, index)}
                                            />
                                        </TableCell>
                                        <CustomTableCell {...{ row, name: "quantity", onChange, index }} />
                                        <CustomTableCell {...{ row, name: "price", onChange, index }} />
                                        <TableCell align="right">
                                            <IconButton onClick={removeRow(index)} style={{color:'red', fontSize: '17px'}}>
                                                <ClearIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid justify="space-between" direction="row-reverse" container spacing={24}>
                        <Grid item>
                            <Button onClick={addRow} variant={'contained'}>
                                Add Product
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid justify="center" container spacing={24}>
                        <Grid item>
                            <Button onClick={closePopup} variant={'outlined'}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={createWarehouseStock} variant={'contained'}>Save</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}