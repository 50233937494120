import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import React, {useState, useEffect} from "react";
import Controls from "../../../components/Common/FormControls/Controls";
import Form from "../../../components/Common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import SnackbarMessage from "../../../components/Common/SnackbarMessage";

const styles = {
    gridItem: {},
    textHelper: {
        fontSize: '10px',
    },
    formInput: {
        marginTop: '8px'
    },
    formButtons: {
        display: 'flex',
        justifyContent: 'center',
    },
    orderCircleDialog: {
        width: '100%',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

function FormSettings(props) {
    const {initialFValues, handleFinished, classes} = props;
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [message, setMessage] = useState('Something went wrong!');
    const [severity, setSeverity] = useState('error');

    useEffect(() => {
        setValues(initialFValues);
    }, [initialFValues])

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value ? value : ''
        })

        validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name && fieldValues.name.trim() ? '' : 'This field is required.';
        if ('code' in fieldValues)
            temp.code = fieldValues.code && fieldValues.code.trim() ? '' : 'This field is required.';
        if ('value' in fieldValues)
            temp.value = fieldValues.value && fieldValues.value.trim() ? '' : 'This field is required.';

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x == '')
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (validate()) {
            handleFinished(values);
            return;
        }

        setMessage('Please fill out all necessary fields!');
        setSeverity('error');
        setMessageShow(true);
    }

    return(
        <Box>
            <SnackbarMessage
                open={messageShow}
                autoHideDuration={6000}
                setOpen={setMessageShow}
                severity={severity}
                message={message}
            />
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={16}>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Controls.Input
                            required={true}
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            fullWidth={true}
                            allowHelperText={false}
                            className={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Controls.Input
                            required={true}
                            name="code"
                            label="Code"
                            value={values.code}
                            onChange={handleInputChange}
                            error={errors.code}
                            fullWidth={true}
                            allowHelperText={false}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Controls.Input
                            required={true}
                            name="value"
                            label="Value"
                            value={values.value}
                            onChange={handleInputChange}
                            error={errors.value}
                            fullWidth={true}
                            allowHelperText={false}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formButtons}>
                            <Controls.ControlButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight: "15px"}}
                                text="Submit"
                            />
                            <Controls.ControlButton
                                text="Reset"
                                onClick={resetForm}
                                variant="outlined"
                                color="primary"
                                type="reset"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Form>
        </Box>
    );
}

export default withStyles(styles)(FormSettings);