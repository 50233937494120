
export const ITEM_FILTER_CONDITION_NAME = 'Condition';
export const ITEM_FILTER_LISTING_TYPE_NAME = 'ListingType';
export const ITEM_FILTER_LOCATED_IN_NAME = 'LocatedIn';

export const mapAspectsToObject = itemAspects => {
    const aspects = {};
    Object.keys(itemAspects).forEach(aspectName => {
        const mappedAspectValues = {};
        const aspectValues = itemAspects[aspectName];
        aspectValues.forEach(aspectValue => {
            mappedAspectValues[aspectValue] = true;
        });
        if (Object.keys(mappedAspectValues).length > 0) {
            aspects[aspectName] = mappedAspectValues;
        }
    });

    return aspects;
}

export const mapItemFilterToObject = itemFilters => {
    const filters = {};
    Object.keys(itemFilters)
        .forEach(filterName => {
            const mappedFilterValues = {};
            const filterValues = itemFilters[filterName];
            if (Array.isArray(filterValues)) {
                filterValues.forEach(filterValue => {
                    mappedFilterValues[filterValue] = true;
                });
                if (Object.keys(mappedFilterValues).length > 0) {
                    filters[filterName] = mappedFilterValues;
                }
            }
        });

    return filters;
}

export const getItemFilterLabel = (name, key) => {
    switch (name) {
        case ITEM_FILTER_CONDITION_NAME:
            return condition[key];
        case ITEM_FILTER_LISTING_TYPE_NAME:
            return listingType[key];
        case ITEM_FILTER_LOCATED_IN_NAME:
            return locatedIn[key];
        default:
            return key;
    }
}

export const condition = {
    1000: 'New',
    1500: 'New other (see details)',
    1750: 'New with defects',
    2000: 'Certified - Refurbished',
    2010: 'Excellent - Refurbished',
    2020: 'Very Good - Refurbished',
    2030: 'Good - Refurbished',
    2500: 'Seller refurbished',
    2750: 'Like New',
    3000: 'Used',
    4000: 'Very Good',
    5000: 'Good',
    6000: 'Acceptable',
    7000: 'For parts or not working',
};

export const listingType = {
    'All': 'All',
    'Auction': 'Auction',
    'AuctionWithBIN': 'AuctionWithBIN',
    'Classified': 'Classified',
    'FixedPrice': 'FixedPrice',
    'StoreInventory': 'StoreInventory'
}

export const locatedIn = {
    'AT': 'Austria',
    'BE': 'Belgium',
    'BG': 'Bulgaria',
    'HR': 'Croatia',
    'CY': 'Cyprus',
    'CZ': 'Czechia',
    'DK': 'Denmark',
    'EE': 'Estonia',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'GR': 'Greece',
    'HU': 'Hungary',
    'IT': 'Italy',
    'MT': 'Malta',
    'NL': 'Netherlands',
    'PL': 'Poland',
    'PT': 'Portugal',
    'RO': 'Romania',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'ES': 'Spain',
    'SE': 'Sweden',
    'US': 'USA',
    'GB': 'United Kingdom',
}