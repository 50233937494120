import { createSlice } from '@reduxjs/toolkit'

export const isUpdateSearchResultsSlice = createSlice({
    name: 'isUpdateSearchResults',
    initialState: {
        value: false,
    },
    reducers: {
        setIsUpdateSearchResults: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setIsUpdateSearchResults } = isUpdateSearchResultsSlice.actions

export const selectIsUpdateSearchResults = state => state.isUpdateSearchResults.value

export default isUpdateSearchResultsSlice.reducer