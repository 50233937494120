import React, {useEffect, useState} from "react";
import axios from '../../helpers/axios-api';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from "@mui/icons-material/Close";
import {Alert, Badge, Collapse, Grid, IconButton, Select, SwipeableDrawer, Tooltip} from "@mui/material";
import {getOffset, htmlDecode} from "../../helpers/common";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import HtmlPopup from "../../components/Popups/HtmlPopup";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChatIcon from '@mui/icons-material/Chat';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Stack from "@mui/material/Stack";
import {
  EVENT_HIDE_EBAY_SEARCH_RESULT_LABEL,
  triggerEvent
} from "../../helpers/events";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import SnackbarMessage from "../../components/Common/SnackbarMessage";
import EbaySearchResultsFooter from "./components/EbaySearchResultsFooter";
import EbayResultsItemCell from "./components/EbayResultsItemCell";
import {useDispatch, useSelector} from "react-redux";
import {setIsUpdateSearchResults} from "../../features/counter/isUpdateSearchResultsSlice";
import {
  selectRefreshEbaySearchResults,
  setRefreshEbaySearchResults
} from "../../features/ebaySearchResults/refreshEbaySearchResultsSlice";
import {setAlertEbaySearchResults} from "../../features/ebaySearchResults/alertEbaySearchResultsSlice";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  greyText: {
    color: 'grey',
    opacity: '50%',
    "&:hover": {
      color: 'grey',
      opacity: '100%'
    }
  },
  productContainer: {
    maxWidth: '75px',
    maxHeight: '100px',
    display: 'inline-block',
    marginRight: '10px',
  },
  productImg: {
    display: 'inline-block',
    maxWidth: '75px',
    maxHeight: '100px'
  }
};

const pageSize = 20;

const defaultSort = [{ field: 'title', sort: 'asc'}];

const EBAY_RESULTS_FILTER = 'EBAY_RESULTS_FILTER';
const EBAY_RESULTS_SORT = 'EBAY_RESULTS_SORT';
const EBAY_RESULTS_SEARCH = 'EBAY_RESULTS_SEARCH';

function EbaySearchResult(props) {
  let params = new URLSearchParams(props.location.search);
  let searchId = params.get("search");
  let isNew = params.get("is_new");

  const defaultFilter = {
    title: '',
    category: [],
    status: '',
    item_id: '',
    priceFrom: '',
    priceTo: '',
    is_new: isNew || '',
    ebaySearch: searchId ? [searchId] : [],
  };
  let savedFilter = JSON.parse(localStorage.getItem(EBAY_RESULTS_FILTER));
  let categoryFilter = [];
  if (savedFilter && savedFilter.category) {
    categoryFilter = savedFilter.category;
  }
  let ebaySearchFilter = [];
  if (savedFilter && savedFilter.ebaySearch) {
    ebaySearchFilter = savedFilter.ebaySearch;
  }
  if (savedFilter) {
    savedFilter = { ...savedFilter, category: categoryFilter, ebaySearch: ebaySearchFilter };
  }
  const initialFilter = searchId ? defaultFilter : (savedFilter || defaultFilter);

  const { classes } = props;
  const savedSort = JSON.parse(localStorage.getItem(EBAY_RESULTS_SORT));
  const savedSearch = JSON.parse(localStorage.getItem(EBAY_RESULTS_SEARCH));
  const [eBaySearchResult, setEBaySearchResult] = useState([]);
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ebaySearches, setEbaySearches] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [purchaseItemDisable, setPurchaseItemDisable] = useState(true);
  const [batchAction, setBatchAction] = useState('');
  const [tableRowCount, setTableRowCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(savedSort || defaultSort);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [search, setSearch] = useState(savedSearch || '');
  const [viewedAlert, setViewedAlert] = useState(false);
  const [confirmClearAllOpen, setConfirmClearAllOpen] = useState(false);
  const [messageShow, setMessageShow] = useState(false);
  const [message, setMessage] = useState('Something went wrong!');
  const [severity, setSeverity] = useState('error');
  const isNewResultsAppeared = useSelector(selectRefreshEbaySearchResults);
  const dispatch = useDispatch();

  const handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const filterCount = () => {
    let count = 0;
    Object.keys(filter).forEach(function(objectKey, index) {
      if (Array.isArray(filter[objectKey])) {
        const values = filter[objectKey];
        count += values.length > 0 ? 1 : 0;
      } else if (filter[objectKey]) {
        count++;
      }
    });
    return count;
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }

  const getShippingInfo = (shippingInfo) => {
    let shipping = '';
    shipping += shippingInfo.cost ? `<div><span><b>Cost:</b></span>&nbsp;<span>${shippingInfo.cost}&nbsp;${shippingInfo.currency}</span></div>` : '';
    shipping += shippingInfo.shippingType ? `<div><span><b>Shipping Type:</b></span>&nbsp;<span>${shippingInfo.shippingType}</span></div>` : '';
    shipping += shippingInfo.shipTo ? `<div><span><b>Ship To:</b></span>&nbsp;<span>${shippingInfo.shipTo}</span></div>` : '';
    shipping += (shippingInfo.expeditedShipping !== null) ? `<div><span><b>Expedited Shipping:</b></span>&nbsp;<span>${shippingInfo.expeditedShipping ? 'Yes' : 'No'}</span></div>` : '';
    shipping += (shippingInfo.oneDayShipping !== null) ? `<div><span><b>OneDay Shipping:</b></span>&nbsp;<span>${shippingInfo.oneDayShipping ? 'Yes' : 'No'}</span></div>` : '';
    shipping += shippingInfo.handlingTime ? `<div><span><b>Handling Time:</b></span>&nbsp;<span>${shippingInfo.handlingTime} days</span></div>` : '';

    return shipping;
  }

  const eBaySearchTableData = eBaySearchResult.map(eBaySearch => {

    let shipping = '';
    if (eBaySearch.shippingInfo) {
      shipping = getShippingInfo(eBaySearch.shippingInfo);
    }

    return {
      ...eBaySearch,
      search_date: eBaySearch.search_date ? moment(eBaySearch.search_date).format('DD.MM.YYYY') : null,
      shipping: shipping
    };
  });

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    getResults(defaultSort, 0);
  }

  const handleFilterFormReset = (e) => {
    e.preventDefault();
    resetFilter();
  }

  const handleClearAll = () => {
    const filterString = getFilterString();
    axios.patch(`/clear-all-ebay-search-results?${filterString}`)
        .then((response) => {
          if (response.data.ok) {
            setMessage(response.data.message);
            setSeverity('success');
            setMessageShow(true);
            getResults();
            dispatch(setIsUpdateSearchResults(true));
          }
        }).catch(error => {
          setMessage('Something went wrong!');
          setSeverity('error');
          setMessageShow(true);
          console.log(error);
    });
  }

  const resetFilter = () => {
    setFilter({
      category: [],
      title: '',
      item_id: '',
      status: '',
      priceFrom: '',
      priceTo: '',
      is_new: '',
      ebaySearch: [],
    });
  }

  const changeSort = (sortData) => {
    setSort(sortData);
    getResults(sortData);
  }

  const getSortField = field => {
    let result = field;

    return result;
  }

  const hideRow = rowId => {
    const row = document.querySelector(`[data-id='${rowId}']`);
    if (row) {
      row.style.display = 'none';
    }
  }

  const getUrl = (newSort = null, newPage = null) => {
    const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;

    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + getSortField(param.field);
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/get-ebay-search-results?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString;

    const filterString = getFilterString();
    if (filterString.length > 0) {
      url = `${url}&${filterString}`;
    }

    return url;
  }

  const getFilterString = () => {
    let filterString = '';
    const filterParams = [];

    if (search.length > 0) {
      return `search=${encodeURIComponent(search)}`;
    }

    if (filter.category.length > 0) {
      const categories = filter.category.filter(categoryId => !!categoryId);
      categories.forEach(categoryId => filterParams.push(`filter[category_id]=${categoryId}`));
    }

    if (filter.title && filter.title.length > 0) {
      filterParams.push(`filter[title]=${filter.title}`);
    }

    if (filter.item_id && filter.item_id.length > 0) {
      filterParams.push(`filter[item_id]=${filter.item_id}`);
    }

    if (filter.status && filter.status.toString().length > 0) {
      filterParams.push(`filter[status]=${filter.status}`);
    }

    if (filter.priceFrom && filter.priceFrom.toString().length > 0) {
      filterParams.push(`filter[price_from]=${filter.priceFrom}`);
    }

    if (filter.priceTo && filter.priceTo.toString().length > 0) {
      filterParams.push(`filter[price_to]=${filter.priceTo}`);
    }

    if (filter.is_new && filter.is_new.toString().length > 0) {
      filterParams.push(`filter[is_new]=${filter.is_new}`);
    } else if (isNew) {
      filterParams.push(`filter[is_new]=${isNew}`);
    }

    if (filter.ebaySearch.length > 0) {
      const ebaySearches = filter.ebaySearch.filter(ebaySearchId => !!ebaySearchId);
      ebaySearches.forEach(ebaySearchId => filterParams.push(`filter[search_id]=${ebaySearchId}`));
    }

    if (filterParams.length > 0) {
      filterString = filterParams.join('&')
    }

    return filterString;
  }

  const handleFilterCollapse = () => setExpandedFilter(!expandedFilter);

  const handleViewed = id => {
    axios.patch(`/set-ebay-search-results-viewed/${id}`)
        .then((response) => {
          if (response.data.showAlert) {
            dispatch(setIsUpdateSearchResults(true));
            triggerEvent(EVENT_HIDE_EBAY_SEARCH_RESULT_LABEL, {ebaySearchResultId: id});
            setViewedAlert(true);
          }
        }).catch(error => {
      console.log(error);
    });
  }

  const getResults = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then(response => {
          setTableRowCount(response.data.total.count);
          setTotalPrice(response.data.searchResultCost);
          let newArray = response.data.data.map((item, index) => {
            item.link = item.view_item_link;
            item.buyLink = item.link = item.buy_link;
            item.category = item.category_name;
            item.image = item.picture_url;
            item.description = item.description;
            item.price = '$'+item.selling_status.current_price.currency_price;
            item.seller = {
              link: Object.hasOwn(item, 'store_info') ? item.store_info.store_url : '',
              name: Object.hasOwn(item, 'store_info') ? item.store_info.store_name : '',
              rating: Object.hasOwn(item, 'seller_info') && item.seller_info.positive_feedback_percent ? item.seller_info.positive_feedback_percent + '% positive' : '',
              sendMessageLink: item.view_item_link
            }
            item.shippingInfo = {
              cost: item.shipping_info.shipping_service_cost_value ? item.shipping_info.shipping_service_cost_value : null,
              currency: item.shipping_info.shipping_service_cost_currency_id ? item.shipping_info.shipping_service_cost_currency_id : null,
              shippingType: item.shipping_info.shipping_type ? item.shipping_info.shipping_type : null,
              shipTo: item.shipping_info.ship_to_locations ? item.shipping_info.ship_to_locations : null,
              expeditedShipping: item.shipping_info.expedited_shipping ? item.shipping_info.expedited_shipping : null,
              oneDayShipping: item.shipping_info.one_day_shipping_available ? item.shipping_info.one_day_shipping_available : null,
              handlingTime: item.shipping_info.handling_time ? item.shipping_info.handling_time : null,
            }
            setIsLoading(false);
            return item;
          });
          setEBaySearchResult(newArray);
          setIsLoading(false);
        });
  }

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 30, filterable: false, flex: 0.7},
    { field: 'item_id', headerName: 'Item ID', minWidth: 80, filterable: false, flex: 1,
      renderCell: (params) => {
        return <EbayResultsItemCell
          key={params.row.id}
          id={params.row.id}
          itemId={params.row.item_id}
          link={params.row.link}
          viewDate={params.row.view_date}
          handleViewed={handleViewed}
        /> ;
      }
    },
    { field: 'category', headerName: 'Category', minWidth: 125, filterable: false, sortable: false, flex: 1.5},
    { field: 'title', headerName: 'Name', minWidth: 200, filterable: false, flex: 1,
      renderCell: (params) => {
      const description = params.row.description ? <HtmlPopup html={params.row.description} style={{position: 'absolute', top: 0, right: 0}}></HtmlPopup> : null;
        const itemLink = params.row.view_date
            ? <a href={params.row.link} target={'_blank'}>{htmlDecode(params.row.title)}</a>
            : <a href={params.row.link}
                 onClick={() => handleViewed(params.row.id)}
                 onAuxClick={() => handleViewed(params.row.id)}
                 target={'_blank'}
            >{htmlDecode(params.row.title)}</a>;
        return (
            <div>
              <div style={{display: 'flex', alignItems: 'center', position: 'relative', width: '100%', paddingRight: '23px'}}>
                <div className={classes.productContainer}><img className={classes.productImg} src={params.row.image} /></div>
                <div style={{display: 'inline-block', whiteSpace: 'pre-wrap'}}>{itemLink}</div>
                {description}
              </div>
            </div>
        );
      }
    },
    { field: 'price', headerName: 'Price', width: 100, filterable: false, sortable: false, flex: 0.7,
      renderCell: (params) => {
        return (
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
              <Stack direction={'column'} alignItems={'center'} justifyContent={'space-between'}>
              <b style={{fontWeight:'900'}}>{params.row.price}</b>
              <span>
                <IconButton style={{color:'#039f12', fontSize: '17px'}}
                  onClick={() => {
                    handleViewed(params.row.id);
                    window.open(params.row.buyLink);
                  }}
                  onAuxClick={() => {
                    handleViewed(params.row.id);
                    window.open(params.row.buyLink);
                  }}
                >
                  <ShoppingCartIcon/><span>Buy</span>
                </IconButton>
              </span>
              </Stack>
            </div>
        );
      }
    },
    { field: 'location', headerName: 'Location', minWidth: 100, filterable: false, sortable: false, flex: 0.5},
    { field: 'shipping', headerName: 'Shipping', minWidth: 150, filterable: false, sortable: false, flex: 1,
      renderCell: (params) => {
        return (
            <div dangerouslySetInnerHTML={{__html: params.row.shipping}}/>
        );
      }
    },
    { field: 'seller', headerName: 'Seller', width: 200, filterable: false, sortable: false, flex: 1.5,
      renderCell: (params) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <a href={params.row.seller.link}><b>{params.row.seller.name}</b></a><br/>
              <span>{params.row.seller.rating}</span><br/>
              <a style={{display:'inline'}} className={classes.greyText} href={params.row.seller.sendMessageLink} target={'_blank'}><ChatIcon style={{fontSize: '14px', verticalAlign: 'middle'}}></ChatIcon><span style={{verticalAlign: 'middle'}}>Send message</span></a><br/>
            </div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 50,
      sortable: false,
      filterable: false,
      flex: 0.3,
      renderCell: (params) => {
        const skipResult = () => {
          axios.patch(`/update-ebay-search-result/update-status/`+params.row.id, {status: 0})
              .then(() => {
                hideRow(params.row.id);
                dispatch(setIsUpdateSearchResults(true));
              }).catch(error => {
                console.log(error);
              }).finally(() => handleViewed(params.row.id));
        };
        const watchResult = () => {
          axios.patch(`/update-ebay-search-result/update-status/`+params.row.id, {status: 1})
              .then(() => {
                getResults();
              }).catch(error => {
            console.log(error);
          }).finally(() => handleViewed(params.row.id));
        };
        return (
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              {params.row.status && <Tooltip title={'Skip'}><IconButton onClick={skipResult} style={{color: 'rgb(249 3 13)', fontSize: '24px', display: 'flex', flexDirection: 'column'}}>
                <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
              </IconButton></Tooltip>}
              {!params.row.status && <Tooltip title={'Watch'}><IconButton onClick={watchResult} style={{color: '#a5c0d9', fontSize: '24px', display: 'flex', flexDirection: 'column'}}>
                <VisibilityIcon />
              </IconButton></Tooltip>}
            </div>
        )
      }
    }
  ];

  const toggleFilter = expandedFilter =>
      event => {
        if (
            event &&
            (event.type === 'keydown'
                && event.keyCode === 'Tab' ||
                event.keyCode === 'Shift')
        ) {
          return;
        }

        setExpandedFilter(expandedFilter);
      };

  const onTableItemSelection = (selectedItem) => {
    if(selectedItem.length !== 0) {
      setSelectedTableItem(selectedItem);      
      setPurchaseItemDisable(false);
    } else {
      setPurchaseItemDisable(true);
      setSelectedTableItem(selectedItem);
    }
  }

  const getCategories = () => {
    return axios.get(`/get-ebay-search-results-categories`)
  }

  const getEbaySearches = () => {
    return axios.get(`/get-actual-ebay-searches-list`)
  }

  const refreshSearchResults = () => {
    getResults();
    dispatch(setRefreshEbaySearchResults(false));
  }

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getCategories().then(response => setCategories(response.data.data)),
      getEbaySearches().then(response => setEbaySearches(response.data.data)),
    ]).then(() => {
      getResults();
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isNewResultsAppeared) {
      refreshSearchResults();
      dispatch(setAlertEbaySearchResults(true));
    }
  }, [isNewResultsAppeared]);

  useEffect(() => {
    localStorage.setItem(EBAY_RESULTS_FILTER, JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    localStorage.setItem(EBAY_RESULTS_SORT, JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(EBAY_RESULTS_SEARCH, JSON.stringify(search));
  }, [search]);

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    resetFilter();
    getResults(defaultSort, 0);
  }

  const handleBatchFormSubmit = (e) => {
    e.preventDefault();
    let url = null;
    let request = {};
    switch(batchAction) {
      case 'skip':
        url = `/update-ebay-search-results/update-status/batch`;
        request = {ids: selectedTableItem, status: 0};
        break;
      case 'watch':
        url = `/update-ebay-search-results/update-status/batch`;
        request = {ids: selectedTableItem, status: 1};
        break;
    }

    if (url) {
      axios.patch(url, request)
          .then(() => {
            getResults(defaultSort, 0);
            dispatch(setIsUpdateSearchResults(true));
          }).catch(error => {
        console.log('Batch action error');
      });
    }
  }

  const onPageChanged = page => {
    setPage(page);
    getResults(null, page);
  }

  const handleBatchFieldChange = (e) => {
    setBatchAction(e.target.value);
  }

  const iconFilter = expandedFilter
      ? <Badge badgeContent={filterCount()} color="success"><FilterAltOffIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>
      : <Badge badgeContent={filterCount()} color="success"><FilterAltIcon style={{fontSize: '38px', cursor: 'pointer'}}/></Badge>;

  const categoriesOptions = categories.map((category, index) =>
        <MenuItem key={index} value={category.id}>
          {category.name}
        </MenuItem>
  );

  const ebaySearchesOptions = ebaySearches.map((ebaySearch, index) =>
      <MenuItem key={index} value={ebaySearch.id}>
        {ebaySearch.name}
      </MenuItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>eBay Results</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "20px" }}>
            <Stack direction={'row'} alignItems="center" style={{marginBottom: '15px'}}>
              <div style={{ marginRight: "10px" }}>
                <a onClick={handleFilterCollapse}>
                  <span style={{display: "flex", alignItems: "center"}}>
                    {iconFilter}<DoubleArrowIcon style={{marginLeft: "-7px"}} />
                  </span>
                </a>
              </div>
              <SwipeableDrawer
                  anchor={'left'}
                  open={expandedFilter}
                  onClose={toggleFilter(false)}
                  onOpen={toggleFilter(true)}
              >
                <div style={{padding: "15px", maxWidth: "300px"}}>
                  <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <IconButton onClick={() => setExpandedFilter(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <h3 style={{marginTop: "10px"}}>Filter</h3>
                  <form
                      onSubmit={handleFilterFormSubmit}
                      onReset={handleFilterFormReset}
                  >
                    <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        style={{ marginBottom: "30px" }}
                        spacing={2}
                    >
                      <Grid item xs={12}>
                        <TextField
                            id="name-input"
                            name="title"
                            type="text"
                            label={"Name"}
                            placeholder={'Name'}
                            value={filter.title}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="id-input"
                            name="item_id"
                            type="text"
                            label={"Item Id"}
                            placeholder={'Item Id'}
                            value={filter.item_id}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="price-from-input"
                            name="priceFrom"
                            type="number"
                            label={"Price from"}
                            placeholder={'Price from'}
                            value={filter.priceFrom}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            id="price-to-input"
                            name="priceTo"
                            type="number"
                            label={"Price to"}
                            placeholder={'Price to'}
                            value={filter.priceTo}
                            style={{width: "100%"}}
                            onChange={handleFilterFieldChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl style={{width: "100%"}}>
                          <InputLabel style={{backgroundColor: "white"}}>EbaySearch</InputLabel>
                          <Select
                              displayEmpty
                              multiple={true}
                              name="ebaySearch"
                              value={filter.ebaySearch}
                              onChange={handleFilterFieldChange}
                              style={{width: "100%"}}
                          >
                            <MenuItem key="none" value="">None</MenuItem>
                            {ebaySearchesOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl style={{width: "100%"}}>
                          <InputLabel style={{backgroundColor: "white"}}>Category</InputLabel>
                          <Select
                              displayEmpty
                              multiple={true}
                              name="category"
                              value={filter.category}
                              onChange={handleFilterFieldChange}
                              style={{width: "100%"}}
                          >
                            <MenuItem key="none" value="">None</MenuItem>
                            {categoriesOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl style={{width: '100%'}}>
                          <InputLabel style={{backgroundColor: 'white'}}>Status</InputLabel>
                          <Select
                              displayEmpty
                              name="status"
                              value={filter.status}
                              onChange={handleFilterFieldChange}
                              style={{width: '100%'}}>
                            <MenuItem key="none" value="">None</MenuItem>
                            <MenuItem key="1" value="1">Active</MenuItem>
                            <MenuItem key="2" value="0">Skipped</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl style={{width: '100%'}}>
                          <InputLabel style={{backgroundColor: 'white'}}>New</InputLabel>
                          <Select
                              displayEmpty
                              name="is_new"
                              value={filter.is_new}
                              onChange={handleFilterFieldChange}
                              style={{width: '100%'}}>
                            <MenuItem key="1" value="0">All</MenuItem>
                            <MenuItem key="2" value="1">Only new</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "15px"}}>
                          <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{marginRight: "15px"}}
                          >
                            Apply
                          </Button>
                          <Button
                              variant="outlined"
                              color="primary"
                              type="reset"
                          >
                            Reset
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </SwipeableDrawer>
              <div style={{width: "100%"}}>
                <form
                    onSubmit={handleBatchFormSubmit}
                >
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                      style={{ width: "100%" }}
                      spacing={2}
                  >
                    <Grid item xs={5}>
                      <FormControl style={{width: "100%"}}>
                        <InputLabel style={{backgroundColor: "white"}}>Batch Action</InputLabel>
                        <Select
                            displayEmpty
                            name="batchAction"
                            value={batchAction}
                            onChange={handleBatchFieldChange}
                            style={{width: "100%"}}
                        >
                          <MenuItem key="none" value="">None</MenuItem>
                          <MenuItem key="skip" value="skip">Skip</MenuItem>
                          <MenuItem key="watch" value="watch">Watch</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div style={{width: '100%'}}>
                <form onSubmit={handleSearchFormSubmit}>
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                      style={{ width: "100%", justifyContent: 'center'}}
                      spacing={2}
                  >
                    <Grid item xs={7}>
                      <FormControl style={{width: "100%"}}>
                        <TextField
                            id="search-input"
                            name="search"
                            type="text"
                            label={"Search"}
                            placeholder={'Search'}
                            value={search}
                            style={{width: "100%"}}
                            onChange={handleSearchChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div style={{minWidth: '120px'}}>
                <Button variant={"outlined"} onClick={() => setConfirmClearAllOpen(true)}>
                  Clear All
                </Button>
              </div>
            </Stack>
            <div style={{ display: "flex", marginBottom: "15px", width: "100%", justifyContent: "flex-end" }}>
              <ConfirmDialog
                  title={"Delete Results?"}
                  open={confirmClearAllOpen}
                  setOpen={setConfirmClearAllOpen}
                  onConfirm={handleClearAll}
              >
                Are you sure you want to delete all selected by filter results?
              </ConfirmDialog>
              <SnackbarMessage
                open={messageShow}
                autoHideDuration={6000}
                setOpen={setMessageShow}
                severity={severity}
                message={message}
              />
            </div>
            <div style={{width: '100%'}}>
              {viewedAlert ? <Alert
                  style={{marginBottom: '15px'}}
                  severity={'success'}
                  action={
                    <IconButton
                        aria-label={'close'}
                        color={'inherit'}
                        size={'small'}
                        onClick={() => setViewedAlert(false)}
                    >
                      <CloseIcon fontSize={'inherit'} />
                    </IconButton>
                  }
              >Ebay search result has been updated!</Alert> : <></> }
            </div>
            <PureTableStyled
              checkboxSelection={true}
              tableHead={columns}
              tableData={eBaySearchTableData}
              onSelectionModelChange = {(selectedItem) => {onTableItemSelection(selectedItem)}}
              rowCount = {Number(tableRowCount)}
              pageSize = {pageSize}
              page = {page}
              setPage = {onPageChanged}
              onSortModelChange = {changeSort}
              isLoading = {isLoading}
              sortModel={sort}
              components={{Footer: EbaySearchResultsFooter}}
              componentsProps={{
                footer: { totalPrice: totalPrice }
              }}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(EbaySearchResult);
