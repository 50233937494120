export const EVENT_HIDE_EBAY_SEARCH_RESULT_LABEL = 'ebaySearchResultLabel:hide';

export const onEvent = (eventType, listener) => {
    document.addEventListener(eventType, listener);
}

export const offEvent = (eventType, listener) => {
    document.removeEventListener(eventType, listener);
}

export const onceEvent = (eventType, listener) => {
    onEvent(eventType, handleEventOnce);

    const handleEventOnce = event => {
        listener(event);
        offEvent(eventType, handleEventOnce);
    }
}

export const triggerEvent = (eventType, data) => {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}
