import React, {useEffect, useState} from "react";
import axios from '../../helpers/axios-api';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Grid} from "@mui/material";
import {getOffset} from "../../helpers/common";
import PureTableStyled from "../../components/DataTable/PureTableStyled";
import AddSetting from "./components/AddSetting";
import EditSetting from "./components/EditSetting";
import DeleteSetting from "./components/DeleteSetting";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const pageSize = 20;
const defaultSort = [{ field: 'name', sort: 'asc'}];

const Settings = (props) => {
  const { classes } = props;
  const [settings, setSettings] = useState({data: [], total: 0});
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(defaultSort);
  const [isLoading, setIsLoading] = useState(false);

  const settingsTableData = settings.data.map((setting, index) => {
    return {
      id: setting.id,
      key: index,
      name: setting.name ? setting.name : null,
      code: setting.code ? setting.code : null,
      value: setting.value ? setting.value : null,
      createdAt: setting.created_at ? moment(setting.created_at).format('DD.MM.YYYY HH:mm:ss') : null,
      updatedAt: setting.updated_at ? moment(setting.updated_at).format('DD.MM.YYYY HH:mm:ss') : null,
    }
  });

  const changeSort = (sortData) => {
    setSort(sortData);
    getSettings(sortData);
  }

  const getUrl = (newSort = null, newPage = null) => {
    const sortParam = newSort ? newSort : sort;
    const pageParam = (newPage !== null) ? newPage : page;
    let sortString = "&sort=";
    sortParam.forEach(function (param) {
      sortString += (param.sort === 'desc' ? '-' : '+') + param.field;
    });

    const offset = getOffset(pageParam, pageSize);

    let url = `/get-settings?page[limit]=`+pageSize+`&page[offset]=`+offset+sortString

    return url;
  }

  const getSettings = (sortParam = null, pageParam = null) => {
    setIsLoading(true);
    axios.get(getUrl(sortParam, pageParam))
        .then((response) => {
          setSettings(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          console.log('Get settings error');
        });
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 200, filterable: false, sortable: true, flex: 1},
    { field: 'code', headerName: 'Code', width: 200, filterable: false, sortable: true, flex: 1},
    { field: 'value', headerName: 'Value', width: 200, filterable: false, sortable: false, flex: 1},
    { field: 'createdAt', headerName: 'Created', width: 200, filterable: false, sortable: false, flex: 1},
    { field: 'updatedAt', headerName: 'Updated', width: 200, filterable: false, sortable: false, flex: 1},
    { field: 'actions', headerName: 'Actions', width: 200, filterable: false, sortable: false, flex: 1,
      renderCell: params => <>
        <EditSetting
            settingId={params.row.id}
            handleFinish={getSettings}
        />
        <DeleteSetting
            settingId={params.row.id}
            handleFinish={getSettings}
        />
      </>
    },
  ];

  useEffect(() => {
    getSettings();
  }, []);

  const onPageChanged = page => {
    setPage(page);
    getSettings(null, page);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  container justify="flex-end">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Settings</h4>
          </CardHeader>
          <CardBody style={{ paddingTop: "20px" }}>
            <Grid container direction={'row'}>
              <Grid item xs={12}>
                <AddSetting
                  handleFinish={getSettings}
                />
              </Grid>
            </Grid>
            <PureTableStyled
                tableHead={columns}
                tableData={settingsTableData}
                rowCount = {settings.total}
                pageSize = {pageSize}
                page = {page}
                setPage = {onPageChanged}
                onSortModelChange = {changeSort}
                isLoading = {isLoading}
                checkboxSelection = {false}
                sortModel={sort}
            />
          </CardBody>
        </Card>        
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(Settings);
