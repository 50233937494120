import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {GridFooterContainer, GridPagination} from "@mui/x-data-grid";
import {formatter} from "../../../helpers/common";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    totalMain: {
        color: '#9c27b0',
        fontWeight: "bold",
    },
};

const WarehouseFooter = props => {
    const { classes } = props;
    return (
        <GridFooterContainer>
            <Box sx={{ padding: "10px", display: "flex" }}>
                <div>
                    <span>Total Price:&nbsp;</span>
                    <span className={classes.totalMain}>{formatter.format(props.totalPrice)}</span>
                </div>
            </Box>
            <GridPagination />
        </GridFooterContainer>
    );
}

WarehouseFooter.propTypes = {
    totalPrice: PropTypes.number,
};

export default withStyles(styles)(WarehouseFooter);