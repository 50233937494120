import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAmazon} from "@fortawesome/free-brands-svg-icons";
import {Alert, CircularProgress, IconButton} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import TableCell from "@mui/material/TableCell";
import Input from "@material-ui/core/Input";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import axios from '../../../helpers/axios-api';
import Box from "@mui/material/Box";
const productStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    borderBottom: '1px black dashed',
    paddingBottom: '15px'
}

export default function WarehouseItemPublish(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [alert, setAlert] = useState({severity: 'error', message: 'Something went wrong'});
    const [item, setItem] = useState({...props.item, skuAmazon: '', priceAmazon: ''});
    const [viewedAlert, setViewedAlert] = useState(false);
    const { handleItemPublished } = props;

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
        handleItemPublished();
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem({
            ...item,
            [name]: value,
        });
    };

    const save = () => {

        const request = {
            priceAmazon: item.priceAmazon ? parseFloat(item.priceAmazon, 10) : 0,
            skuAmazon: item.skuAmazon
        }

        setPublishing(true);
        axios.patch(
            `/publish-warehouse-stock/${item.id}`,
            request
        ).then((response) => {
            const {ok, message} = response.data.data;
            const severity = ok ? 'success' : 'error';
            setAlert({severity, message});
            setViewedAlert(true);
            setPublishing(false);
        }).catch(err => {
            setViewedAlert(false);
            setPublishing(false);
            console.log(err);
            console.log('Update warehouse stocks error');
        });
    }

    return <div>
            <IconButton sx={{color:"#52bd5b", fontSize: '16px'}} onClick={openModal}>
                <FontAwesomeIcon icon={faAmazon} style={{marginRight: '5px'}} />
                <span>Publish</span>
            </IconButton>
            <Dialog open={modalOpen} onClose={closeModal} maxWidth="l">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Publish
                    <IconButton onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={productStyle}>
                        <img src={item.picture} style={{maxWidth: '100px', height: 'auto', marginRight: '20px'}} />
                        <span style={{fontSize: '16px', fontWeight: 700}}>{item.title}</span>
                    </div>
                    {publishing && <Box>
                        <CircularProgress color="secondary" />
                    </Box>}
                    {!publishing && <>
                        {viewedAlert ? <Alert
                            style={{marginBottom: '15px', display: 'flex', alignItems: 'center', fontSize: '1rem'}}
                            severity={alert.severity}
                            action={
                                <IconButton
                                    aria-label={'close'}
                                    color={'inherit'}
                                    size={'small'}
                                    onClick={() => setViewedAlert(false)}
                                >
                                    <CloseIcon fontSize={'inherit'} />
                                </IconButton>
                            }
                        >{alert.message}</Alert> : <></> }
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Part Number</TableCell>
                                        <TableCell align="center">Price eBay</TableCell>
                                        <TableCell align="center">Amazon Seller SKU</TableCell>
                                        <TableCell align="center">Price Amazon</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="right">{item.partNumber}</TableCell>
                                            <TableCell component="th" scope="row">{`$${item.price}`}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <Input
                                                    name="skuAmazon"
                                                    value={item.skuAmazon}
                                                    onChange={handleInputChange}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                $<Input
                                                    name="priceAmazon"
                                                    value={item.priceAmazon}
                                                    onChange={handleInputChange}
                                                />
                                            </TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid justify="center" container spacing={24}>
                            <Grid item>
                                <Button onClick={closeModal}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={save}>Save</Button>
                            </Grid>
                        </Grid>
                        </>
                    }
                </DialogContent>
            </Dialog>
        </div>;
}