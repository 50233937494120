import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import axios from '../../../helpers/axios-api';
import {
    CircularProgress,
    IconButton, Tooltip,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FormSettings from "./FormSettings";
import withStyles from "@material-ui/core/styles/withStyles";
import SnackbarMessage from "../../../components/Common/SnackbarMessage";
import EditIcon from "@mui/icons-material/Edit";
import {defaultValues} from "./AddSetting";

const styles = {
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '15px',
        width: '100%',
    },
    iconClass: {
        fontSize: '24px',
    },
    iconWrapperClass: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
    },
    iconInnerClass: {
        color: '#ff9900'
    }
}

function EditSetting(props) {
    const {settingId, handleFinish, classes} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [values, setValues] = useState(defaultValues);
    const [isLoading, setIsLoading] = useState(true);
    const [messageShow, setMessageShow] = useState(false);
    const [message, setMessage] = useState('Something went wrong!');
    const [severity, setSeverity] = useState('error');

    const openModal = () => {
        setModalOpen(true);
        getSetting();
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const getSetting = () => {
        setIsLoading(true);
        const url = `/get-setting/${settingId}`
        axios.get(url).then((response) => {
            setValues(response.data.data);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const updateSetting = async (values) => {
        const request = {
            name: values.name ? encodeURIComponent(values.name) : null,
            code: values.code ? encodeURIComponent(values.code) : null,
            value: values.value ? encodeURIComponent(values.value) : null,
        }

        axios.patch(
            `/update-setting/${settingId}`,
            request
        ).then((response) => {
            setModalOpen(false);
            if (response.data.ok) {
                setMessage(response.data.message);
                setSeverity('success');
                setMessageShow(true);
                closeModal();
                handleFinish();
            } else {
                setMessage(response.data.message);
                setSeverity('error');
                setMessageShow(true);
            }
        });
    }

    const formValues = {
        name: values.name ? values.name : '',
        code: values.code ? values.code : '',
        value: values.value ? values.value : '',
    }

    return (
        <Stack spacing={1} direction="row">
            <Tooltip title={'Edit'}>
                <div>
                    <IconButton onClick={openModal} className={classes.iconClass} >
                        <div className={classes.iconWrapperClass}>
                            <EditIcon className={classes.iconInnerClass}/>
                        </div>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog open={modalOpen} onClose={closeModal} fullWidth maxWidth="sm">
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Edit Setting
                    <IconButton onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {isLoading && <CircularProgress color="secondary" />}
                    {!isLoading && <FormSettings
                        initialFValues={formValues}
                        handleFinished={updateSetting}
                    />}
                </DialogContent>
            </Dialog>
            <SnackbarMessage
                open={messageShow}
                autoHideDuration={6000}
                setOpen={setMessageShow}
                severity={severity}
                message={message}
            />
        </Stack>
    );
}

export default withStyles(styles)(EditSetting);