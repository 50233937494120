import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  warehouseOrderListOrderCell: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const WarehouseListOrderCell = props => {
  const { orderId, classes } = props;

  const order = orderId ? <>
    <b>eBay</b>
    <a href={`https://www.ebay.com/vod/FetchOrderDetails?orderId=${orderId}`}>{orderId}</a>
  </> : <strong>manual</strong>;

  return (
    <div className={classes.warehouseOrderListOrderCell}>
      {order}
    </div>
  );
}

export default withStyles(styles)(WarehouseListOrderCell);
