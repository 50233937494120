import InfoIcon from '@mui/icons-material/Info';
import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {htmlDecode} from "../../helpers/common";
import Iframe from "../Common/Iframe";
export default function HtmlPopup(props){
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    }

    const closePopup = () => {
        setIsOpen(false);
    }

    const html = props.html ? htmlDecode(props.html) : null;

    return (
        <div style={props.style}>
            <IconButton onClick={openPopup} style={{padding: '0'}}>
                <InfoIcon></InfoIcon>
            </IconButton>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    Description
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Iframe content={html} />
                </DialogContent>
            </Dialog>
        </div>
    );
}